import React from "react";
import {CSSProperties} from "@emotion/serialize";
import {AppColors} from "globals/appcolors";

export const SelectStyles = {
  control: (base: CSSProperties, state: any) => ({
    ...base,
    background: "#FAFAFB",
    border: "1px solid #E1E1E1",
    boxSizing: "border-box",
    fontSize: "0.75rem",
    borderRadius: 6,
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #E1E1E1",
    },
  }),
  container: (provided: CSSProperties) => ({
    ...provided,
    outline: "none",
    fontSize: "0.75rem",
    borderRadius: 6,
    background: "#FAFAFB",
  }),
  option: (provided: CSSProperties, state: any) => ({
    ...provided,
  }),
};
export const halfModalContainer: React.CSSProperties | undefined = {
  background: AppColors.WHITE,
  borderRadius: 15,
  padding: "2rem",
  maxHeight: "70vh",
  maxWidth: "50vw",
  overflowY: "scroll",
  minWidth: "50vw",
};
