import moment, {Moment} from "moment";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import GoogleAnalytics from "react-ga";
import CryptoJS from "crypto-js";
import {Resume} from "@affinda/affinda";

import {AppConfig} from "appConfig";
import {DateType} from "./types/globalTypes";
import {
  CandidateDetails,
  DeleteAssessmentTestResponse,
  InterviewInstructionsResponse,
  LoginResponse,
  ParseResume,
  SkillsResponse,
} from "./types/APIResponses";
import {COOKIESKEYS} from "./constants/cookiesKeys";
import axiosGetModule from "network/Get/AxiosGetModule";
import {APIEndPoints} from "./constants/endPoints";
import {API_DECODE_SECRET_KEY} from "./constants/keys";
import {affindaClient} from "./constants/affindaConstants";
import {toast} from "react-toastify";
import {sourcebaeInstance} from "./axiosConstants";

export const getDeviceName = () => {
  let deviceName = "Unknown";
  const deviceNameMapping: any = {
    "Windows Phone": /Windows Phone/i,
    Windows: /Win/i,
    iPhone: /iPhone/i,
    iPad: /iPad/i,
    Android: /Android/i,
    Blackberry: /BlackBerry/i,
    Macintosh: /Mac/i,
    Linux: /Linux/i,
    Palm: /Palm/i,
  };

  Object.keys(deviceNameMapping).map(
    (platform) =>
      navigator.userAgent.match(deviceNameMapping[platform]) &&
      (deviceName = platform)
  );
  return deviceName;
};

export const getDateDifferenceInSeconds = (
  startDate: DateType,
  endDate: DateType
) => {
  const seconds = (endDate?.getTime()! - startDate?.getTime()!) / 1000;
  return seconds;
};

export const staticHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
});

export const formatYYYYMMDD = (date?: Moment | null) => {
  return moment(date || moment())
    .format("YYYY-MM-DD")
    .toString();
};

export const formatDDMMMYYYY = (date?: Moment | string | null) => {
  return moment(date || moment())
    .format("DD MMM YYYY")
    .toString();
};

export const deleteAssessmentTest = async (id: number) => {
  try {
    return await axios.request<DeleteAssessmentTestResponse>({
      url: `${AppConfig.Configuration.BASE_NODE_URL}/assesment-templates/delete/${id}`,
      method: "DELETE",
      headers: staticHeaders(),
    });
  } catch (e) {
    return null;
  }
};

export const fetchSkills = async () => {
  try {
    return axiosGetModule(APIEndPoints.GET_SKILLS_NODE).then(
      (res: SkillsResponse) => res.data
    );
  } catch (e) {
    return null;
  }
};

export const base64Toblob = async (data: string) => {
  return await fetch(data).then((res) => res.blob());
};

export const initMixpanel = () => {
  mixpanel.init("3707ffdde83fed1ca827369a1f47dc5a");
};

export const createMixPanelUser = (userDetails: LoginResponse) => {
  const {user} = userDetails.data;
  mixpanel.alias(user.id.toString());
  mixpanel.people.set({
    Type:
      user.role_id === 1
        ? "Admin"
        : user.role_id === 2
        ? "Manager"
        : user.role_id === 3
        ? "Developer"
        : user.role_id === 4 && "BDE",

    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
  });
  mixpanel.identify(user.id.toString());
};

export const postMixPanelEvent = (event: string) => {
  mixpanel.track(event);
};

export const postGoogleAnalyticsEvent = (category: string) => {
  const eventTracker = (action: string, label: string) => {
    GoogleAnalytics.event({category, action, label});
  };
  return eventTracker;
};

export const decodeEncodedAPIResponse = (
  responseString: string,
  res: any
): any => {
  let bytes = CryptoJS.AES.decrypt(
    `${responseString}`,
    API_DECODE_SECRET_KEY
  ).toString(CryptoJS.enc.Utf8);

  return {...res, data: JSON.parse(bytes)};
};

export const getIPOfUser = async () => {
  return await axios
    .get("https://api.ipify.org/?format=json")
    .then((res) => res.data.ip)
    .catch((err) => "");
};

// replace
export const parseResume = async (data: any) => {
  return await axios.post<ParseResume>(APIEndPoints.PARSE_RESUME, data, {
    headers: staticHeaders(),
  });
};

// export function parseResume(file: File): Promise<Resume> {
//   return affindaClient.createResume({file});
// }

export function getParentContainerDimensions(
  element: Element
): {width: number; height: number} | null {
  if (element.parentElement !== null)
    return {
      width: element.parentElement.clientWidth,
      height: element.parentElement.clientHeight,
    };
  else return null;
}

export function getElementDimensions(
  element: Element
): {width: number; height: number} | null {
  if (element !== null) {
    return {width: element.clientWidth, height: element.clientHeight};
  }
  return null;
}

export function convertToBase64(
  file: any,
  callBack: (base64String: string | ArrayBuffer | null) => void
) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    callBack(reader.result);
  };
}

export function capitalizeWord(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export async function getAssessmentDetails(
  assessmentId: string
): Promise<CandidateDetails | null | undefined> {
  return axios
    .get(`${APIEndPoints.CANDIDATE_DETAILS_NODE(assessmentId)}`, {
      headers: staticHeaders(),
    })
    .then((res) => {
      if (res.data.success === true && res.data.code === 200) {
        const {data} = res.data;
        const decodedResponse: CandidateDetails = decodeEncodedAPIResponse(
          data,
          res.data
        );
        return decodedResponse;
      }
    })
    .catch((e) => {
      toast.error("Unable to get data");
      return null;
    });
}

export async function getSpeechSounds(): Promise<SpeechSynthesisVoice[]> {
  return new Promise((resolve, reject) => {
    let synth = window.speechSynthesis;
    let id = setInterval(() => {
      if (synth.getVoices().length !== 0) {
        resolve(synth.getVoices());
        clearInterval(id);
      }
    }, 10);
  });
}

export function base64ToBlob(base64: string) {
  const byteString = atob(base64.split(",")[1]);
  const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {type: mimeString});
}

export const uploadFileToS3 = async (
  file: File | Blob | string,
  fileName?: string,
  fileType?: string,
  fileFormatt?: string
): Promise<string> => {
  const formData = new FormData();
  let fileObject;
  if (fileFormatt === "base64") {
    const converted = base64ToBlob(file as string);
    fileObject = new File([converted], fileName ?? "", {
      type: fileType,
    });
  }
  if (fileFormatt === "mp3") {
    fileObject = new File([file], fileName ?? "", {
      type: fileType,
    });
  }
  formData.append("file", fileObject ?? file);
  const res = await sourcebaeInstance
    .post(`/agency/media/upload`, formData)
    .then((response) => {
      const resumeLink: string = response?.data?.data;
      return resumeLink;
    })
    .catch((err) => {
      console.log(err, "err");
    });
  return res ?? "";
};
