import React from "react";
import {Typography} from "@mui/material";
import Modal from "react-responsive-modal";

import ViewButton from "components/Containers/Button/Button/ViewButton";
import strings from "globals/constants/strings.json";
import {CustomModalBaseProps} from "globals/types/globalTypes";

interface IProps extends CustomModalBaseProps {
  tabSwitchCount: number;
  remainingTime: number;
}

export const TabSwitchedAlert: React.FC<IProps> = ({
  open,
  onClose,
  tabSwitchCount,
  remainingTime,
}) => {
  const renderTabSwitchText =
    () => `Please refrain from switching tabs otherwise your test shall be
  ended. You have ${5 - tabSwitchCount} more ${
      5 - tabSwitchCount > 1 ? "attempts" : "attempt"
    }. The test will also be finished if you do not return to the screen with the next ${
      45 - remainingTime
    } seconds.`;

  return (
    <Modal
      center
      open={open}
      onClose={onClose}
      showCloseIcon={false}
      styles={{
        modal: {
          maxWidth: "30%",
          borderRadius: 10,
          padding: "2rem",
        },
      }}
    >
      <Typography className="font-semibold text-sm mb-3">
        {renderTabSwitchText()}
      </Typography>
      <ViewButton
        name={strings.interview.alert_box_button_text}
        onClick={onClose}
        style={{height: 40}}
      />
    </Modal>
  );
};
