import React from "react";
import {SwipeableDrawer} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { Theme, makeStyles } from '@material-ui/core/styles';
import {makeStyles} from "@mui/styles";

// export const useStylesDrawer = makeStyles((theme: Theme) => ({
//     drawerContainer: {
//         width: '591px',
//         zIndex: 99999,
//         [theme.breakpoints.down(600)]: {
//             width: '100%',
//             // top: '59px',
//             // zIndex:: '591px',
//         zI'99'
//         },
//     },

// }));
const useStyles = makeStyles({
  drawerContainer: {
    widthndex: 99999,
    // [theme.breakpoints.down(600)]: {
    //     width: '100%',
    //     top: '59px',
    //     zIndex:'99'
    // },
  },
});

export interface DrawerViewProps {
  onClose: React.ReactEventHandler;
  onOpen?: React.ReactEventHandler;
  className?: string;
  children: React.ReactNode;
  direction?: "bottom" | "left" | "right" | "top";
  HeaderComponent?: () => void;
  FooterComopnent?: () => void;
  variant?: "permanent" | "persistent" | "temporary";
  hasBackdrop?: boolean;
}

export const DrawerView: React.FC<DrawerViewProps> = (props) => {
  const {
    FooterComopnent,
    HeaderComponent,
    direction = "right",
    className = "",
    onClose,
    onOpen,
    children,
    hasBackdrop = true,
    variant,
  } = props;

  const isMobile = useMediaQuery("(max-width:600px)");
  const classes = useStyles();

  return (
    //@ts-ignore
    <SwipeableDrawer
      anchor={direction}
      open={true}
      onClose={onClose}
      onOpen={() => onOpen}
      // className={`${className} ${classes.drawerContainer}`}
      classes={{paper: `${className} ${classes.drawerContainer}`}}
      ModalProps={{BackdropProps: {invisible: isMobile ? true : !hasBackdrop}}}
      variant={isMobile ? "permanent" : variant}
      transitionDuration={0}
    >
      {HeaderComponent && HeaderComponent()}
      {children}
      {FooterComopnent && FooterComopnent()}
    </SwipeableDrawer>
  );
};

// export default DrawerView;
