import React from "react";
import styles from "./CollegeLandingPageStyles.module.scss";
import strings from "globals/constants/strings.json";
import {Images} from "Images/index";

export const OurCandidate: React.FC = () => {
  const Candidate = [
    {bg: Images.greenCandidateBG, img: Images.candidate},
    {bg: Images.blueCandidateBG, img: Images.candidate},
    {bg: Images.yellowCandidateBG, img: Images.candidate},
    {bg: Images.pinkCandidateBG, img: Images.candidate},
  ];
  return (
    <div className={styles.our_candidate_wrap}>
      <div className={styles.our_candidate_title_section}>
        <img src={Images.ourCandidate} alt="" />
        <h1 className={styles.title}>
          {strings.College_landing.our_candidate.title}
        </h1>
      </div>
      <div className={styles.candidate_wrap}>
        {Candidate?.map((item, index) => {
          return (
            <div
              className={styles.candidate}
              style={{backgroundImage: `url(${item.bg})`}}
            >
              <img src={item.img} alt="" />
            </div>
          );
        })}
      </div>
    </div>
  );
};
