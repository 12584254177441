import React from "react";
import styles from "./CollegeLandingPageStyles.module.scss";
import strings from "globals/constants/strings.json";
import {ClientLogo} from "./ClientLogo";

export const OurClient: React.FC = () => {
  return (
    <div className={styles.ourClient_wrap}>
      <h1 className={styles.title}>
        {strings.College_landing.our_client.title}
      </h1>
      <div className={styles.logo_wrap}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4]?.map((logo) => {
          return (
            <div className={styles.logo}>
              <img src={ClientLogo.sequioa} alt="" />
            </div>
          );
        })}
      </div>
    </div>
  );
};
