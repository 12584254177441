import {AppColors} from "globals/appcolors";
import strings from "globals/constants/strings.json";

export const QuestionTimer = 60;
export const SecondRoundPerQuestionTime = 50; // 1.5 minutes => 90 seconds
export const SecondRoundScreenshotsPerQuestion = 5;
export const NumberOfQuestionsInSecondRound = 10;

export const IntroductionQuestions = [
  "Please tell us something about yourself",
  "How many projects have you worked on till now?",
  "Please mention what's your stregth and how it helps you in your working.",
];

export const LEGEND = [
  {
    title: strings.interview.legend.attempted,
    backgroundColor: AppColors.ANSWERED_GREEN,
  },
  {
    title: strings.interview.legend.unanswered,
    backgroundColor: AppColors.WHITE_300,
  },
];

export const interviewHeaders = {
  Authorization: `Bearer ${localStorage.getItem("candidateToken")}`,
  Accept: "application/json",
};

export enum SENTIMENTS {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
  NEUTRAL = "NEUTRAL",
}

export const TabSwitchWarning =
  "You're not allowed to switch tabs. Please return back to the page before your test ends.";

export const ReportCardDummyMiscData = [
  {
    title: "Communication Skill",
    scored: 5,
    total: 10,
    subheader: "Benefit of good communication skills",
    points: [
      "Improved relationships with colleagues, customers, and clients",
      "Enhanced personal and professional credibility",
      "Ability to effectively communicate ideas and proposals",
    ],
  },
  {
    title: "Accuracy",
    scored: 0,
    total: 10,
    subheader: "Benefit of good and precise accuracy skills",
    points: [
      "Improved relationships with colleagues, customers, and clients",
      "Enhanced personal and professional credibility",
      "Ability to effectively communicate ideas and proposals",
    ],
  },
  {
    title: "Behaviour",
    scored: 5,
    total: 10,
    subheader: "Benefit of good behaviour skills",
    points: [
      "Improved relationships with colleagues, customers, and clients",
      "Enhanced personal and professional credibility",
      "Ability to effectively communicate ideas and proposals",
    ],
  },
];

export const facialExpressions = {
  default: {},
  smile: {
    browInnerUp: 0.17,
    eyeSquintLeft: 0.4,
    eyeSquintRight: 0.44,
    noseSneerLeft: 0.1700000727403593,
    noseSneerRight: 0.14000002836874015,
    mouthPressLeft: 0.61,
    mouthPressRight: 0.41000000000000003,
  },
  funnyFace: {
    jawLeft: 0.63,
    mouthPucker: 0.53,
    noseSneerLeft: 1,
    noseSneerRight: 0.39,
    mouthLeft: 1,
    eyeLookUpLeft: 1,
    eyeLookUpRight: 1,
    cheekPuff: 0.9999924982764238,
    mouthDimpleLeft: 0.414743888682652,
    mouthRollLower: 0.32,
    mouthSmileLeft: 0.35499733688813034,
    mouthSmileRight: 0.35499733688813034,
  },
  sad: {
    mouthFrownLeft: 1,
    mouthFrownRight: 1,
    mouthShrugLower: 0.78341,
    browInnerUp: 0.452,
    eyeSquintLeft: 0.72,
    eyeSquintRight: 0.75,
    eyeLookDownLeft: 0.5,
    eyeLookDownRight: 0.5,
    jawForward: 1,
  },
  surprised: {
    eyeWideLeft: 0.5,
    eyeWideRight: 0.5,
    jawOpen: 0.351,
    mouthFunnel: 1,
    browInnerUp: 1,
  },
  angry: {
    browDownLeft: 1,
    browDownRight: 1,
    eyeSquintLeft: 1,
    eyeSquintRight: 1,
    jawForward: 1,
    jawLeft: 1,
    mouthShrugLower: 1,
    noseSneerLeft: 1,
    noseSneerRight: 0.42,
    eyeLookDownLeft: 0.16,
    eyeLookDownRight: 0.16,
    cheekSquintLeft: 1,
    cheekSquintRight: 1,
    mouthClose: 0.23,
    mouthFunnel: 0.63,
    mouthDimpleRight: 1,
  },
  crazy: {
    browInnerUp: 0.9,
    jawForward: 1,
    noseSneerLeft: 0.5700000000000001,
    noseSneerRight: 0.51,
    eyeLookDownLeft: 0.39435766259644545,
    eyeLookUpRight: 0.4039761421719682,
    eyeLookInLeft: 0.9618479575523053,
    eyeLookInRight: 0.9618479575523053,
    jawOpen: 0.9618479575523053,
    mouthDimpleLeft: 0.9618479575523053,
    mouthDimpleRight: 0.9618479575523053,
    mouthStretchLeft: 0.27893590769016857,
    mouthStretchRight: 0.2885543872656917,
    mouthSmileLeft: 0.5578718153803371,
    mouthSmileRight: 0.38473918302092225,
    tongueOut: 0.9618479575523053,
  },
};

export const corresponding = {
  A: "viseme_PP",
  B: "viseme_kk",
  C: "viseme_I",
  D: "viseme_AA",
  E: "viseme_O",
  F: "viseme_U",
  G: "viseme_FF",
  H: "viseme_TH",
  X: "viseme_PP",
};
