import React from "react";
import Modal from "react-responsive-modal";
import {Typography, Checkbox, Button} from "@mui/material";

import styles from "components/Containers/Cards/LoginCard/LoginCardSass.module.scss";
import {Row} from "components/Row";

import {
  AssessmentData,
  CSS,
  CustomModalBaseProps,
} from "globals/types/globalTypes";
import strings from "globals/constants/strings.json";
import {AppColors} from "globals/appcolors";

interface IProps extends CustomModalBaseProps {
  availableAssessments: AssessmentData[];
  startedAssessments: AssessmentData[];
  selectedAssessment: AssessmentData | null;
  setSelectedAssessment: React.Dispatch<
    React.SetStateAction<AssessmentData | null>
  >;
  onClickProceed: () => void;
  modalStyle?: CSS;
}

interface AssessmentProps {
  assessment: AssessmentData;
  selectedAssessment: AssessmentData | null;
  setSelectedAssessment: React.Dispatch<
    React.SetStateAction<AssessmentData | null>
  >;
}

export const AvailableAssessmentsForCandidate: React.FC<IProps> = ({
  open,
  onClose,
  availableAssessments,
  startedAssessments,
  selectedAssessment,
  setSelectedAssessment,
  onClickProceed,
  modalStyle,
}) => {
  const Assessment: React.FC<AssessmentProps> = ({
    assessment,
    selectedAssessment,
    setSelectedAssessment,
  }) => {
    const handleChange = () => {
      if (assessment.assessment_url === selectedAssessment?.assessment_url)
        setSelectedAssessment(null);
      else setSelectedAssessment(assessment);
    };

    return (
      <div className={styles.row}>
        <Checkbox
          checked={
            assessment.assessment_url === selectedAssessment?.assessment_url
          }
          sx={{
            color: AppColors.REPORTCARD_SECONDARY,
            "&.Mui-checked": {
              color: AppColors.REPORTCARD_SECONDARY,
            },
          }}
          onChange={handleChange}
        />
        <Typography>{assessment.name}</Typography>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      styles={{modal: modalStyle}}
      showCloseIcon={false}
      center
    >
      {availableAssessments.length > 0 || startedAssessments.length > 0 ? (
        <Typography
          variant="h6"
          sx={{
            margin: "1rem 0",
            fontWeight: "bold",
          }}
        >
          {strings.candidate_login.modal_title}
        </Typography>
      ) : (
        <>
          <Typography variant="h4" sx={{fontWeight: "bold", marginBottom: 1}}>
            {strings.uh_oh}
          </Typography>
          <Typography variant="body1">
            We didn't find any available assessments for you. There is a good
            chance that assessments created for you have either expired or you
            do not have any assessments to your name currently.
          </Typography>
        </>
      )}

      {availableAssessments.length > 0 && (
        <>
          <Typography
            variant="h6"
            sx={{
              margin: "1rem 0",
              fontWeight: "bold",
            }}
          >
            {strings.interview_status.scheduled}
          </Typography>
          <Row>
            <div className="flex-0.8">
              {availableAssessments.map((assessment) => (
                <Assessment
                  assessment={assessment}
                  selectedAssessment={selectedAssessment}
                  setSelectedAssessment={setSelectedAssessment}
                  key={assessment.id}
                />
              ))}
            </div>
            <div className="flex-0.2">
              <Button
                disabled={!selectedAssessment}
                style={{
                  background: selectedAssessment
                    ? AppColors.REPORTCARD_SECONDARY
                    : `${AppColors.REPORTCARD_SECONDARY}a0`,
                  color: AppColors.WHITE,
                  paddingInline: "1.5rem",
                }}
                sx={{
                  marginTop: "1rem",
                }}
                onClick={onClickProceed}
              >
                Proceed
              </Button>
            </div>
          </Row>
        </>
      )}

      {startedAssessments.length > 0 && (
        <>
          <Typography
            variant="h6"
            sx={{
              margin: "1rem 0",
              fontWeight: "bold",
            }}
          >
            {strings.interview_status.started}
          </Typography>
          <Row>
            <div className="flex-0.8">
              {startedAssessments.map((assessment) => {
                return (
                  <Assessment
                    assessment={assessment}
                    key={assessment.id.toString()}
                    selectedAssessment={selectedAssessment}
                    setSelectedAssessment={setSelectedAssessment}
                  />
                );
              })}
            </div>
            <div className="flex-0.2">
              <Button
                disabled={!selectedAssessment}
                style={{
                  background: selectedAssessment
                    ? AppColors.REPORTCARD_SECONDARY
                    : `${AppColors.REPORTCARD_SECONDARY}d0`,
                  color: AppColors.WHITE,
                  paddingInline: "1.5rem",
                }}
                sx={{
                  marginTop: "1rem",
                }}
                onClick={onClickProceed}
              >
                Proceed
              </Button>
            </div>
          </Row>
        </>
      )}
    </Modal>
  );
};
