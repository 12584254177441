import React, {useState, useEffect} from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import moment, {Moment} from "moment";
import {Switch} from "@mui/material";
import styles from "./style.module.scss";

import DatePicker from "components/Pickers/DatePicker/DatePicker";
import {RightArrow} from "components/Icons";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";

import strings from "globals/constants/strings.json";
import {
  CSS,
  DatePickerBox,
  SelectOption,
  SkillsData,
} from "globals/types/globalTypes";

import {AppColors} from "globals/appcolors";
import {APIEndPoints} from "globals/constants/endPoints";
import {experienceOptions} from "globals/constants/dropdownConstants";
import axiosGetModule from "network/Get/AxiosGetModule";

type skillsType = {
  label: string;
  value: number;
  questions_count?: {
    non_organization_question_level_1: number;
    organization_question_level_1: number;
    non_organization_question_level_2: number;
    organization_question_level_2: number;
    non_organization_question_level_3: number;
    organization_question_level_3: number;
  };
};
interface Props {
  next: () => void;
}

const skillsError = "Please select some skills";

export const BasicAssessmentDetails: React.FC<Props> = ({next}) => {
  const animatedComponents = makeAnimated();
  const {
    assessment_details: reusable_assessment_details,
    createAssesmentValues,
    setCreateAssesmentValues,
  } = useAppCommonDataProvider();

  const [assessmentDate, setAssessmentDate] = useState<Moment | null>(moment());
  const [skills, setskills] = useState<any>();
  const [selectedTags, setSelectedTags] = useState<SelectOption[] | []>([]);
  const [totalQuestions, settotalQuestions] = useState<number | undefined>();
  const [isOrgOnly, setisOrg] = useState<number>(1);
  const [errors, setErrors] = useState<string[]>([]);
  const [experienceOpt, setExperienceOpt] = useState({
    label: experienceOptions[0].label,
    value: experienceOptions[0].value,
  });

  const datePickerInputStyle: CSS = {
    height: 50,
    background: "transparent",
    width: "100%",
  };

  const datePickerContainerStyle: DatePickerBox = {
    background: AppColors.WHITE_800,
    paddingX: "10px",
    height: 50,
    borderRadius: "6px",
    border: `1px solid ${AppColors.WHITE_300}`,
  };

  useEffect(() => {
    fetchSkills();
  }, []);

  useEffect(() => {
    let total: number = 0;
    let key: string = isOrgOnly
      ? `organization_question_level_${experienceOpt.value}`
      : `non_organization_question_level_${experienceOpt.value}`;
    selectedTags.forEach((item: skillsType) => {
      //@ts-ignore
      total = total + parseInt(item.questions_count[key] ?? 0);
    });
    settotalQuestions(total);
  }, [selectedTags, isOrgOnly, experienceOpt]);

  useEffect(() => {
    //@ts-ignore
    setCreateAssesmentValues?.({
      ...createAssesmentValues,
      experience_value: experienceOptions[0].value,
      switch: isOrgOnly,
      date:
        createAssesmentValues?.date !== undefined
          ? createAssesmentValues.date
          : assessmentDate,
    });

    if (createAssesmentValues?.experience_value != undefined) {
      const data = experienceOptions.find(
        (i) => i.value == createAssesmentValues?.experience_value
      );
      if (data !== undefined)
        setExperienceOpt({...data, label: data.label, value: data.value});
    }

    setAssessmentDate(
      createAssesmentValues !== null
        ? createAssesmentValues.date !== undefined
          ? createAssesmentValues.date
          : moment()
        : moment()
    );
  }, []);

  const formatSkills = (list: SkillsData[]) => {
    let generatedFormat: Array<any> = [];
    let prefilledTags: any[] = [];
    list?.forEach((element) => {
      generatedFormat.push({
        label: element.skill,
        value: element.id,
        questions_count: element.question_count,
      });
    });
    setskills(generatedFormat);
    if (createAssesmentValues?.skills !== undefined) {
      createAssesmentValues.skills.forEach((skill) => {
        const retrievedSkill = list.find((elm) => elm.id == skill);
        prefilledTags.push({
          label: retrievedSkill?.skill,
          value: retrievedSkill?.id,
          questions_count: retrievedSkill?.question_count,
        });
      });
      setSelectedTags(prefilledTags);
    }
  };

  const fetchSkills = async () => {
    await axiosGetModule(APIEndPoints.GET_SKILL_WITH_QUE_COUNT_NODE)
      .then((res) => {
        formatSkills(res.data);
      })
      .catch((err) => console.log("fetchSkills err :", err));
  };

  const handleTagsChange = (newElements: any) => {
    setErrors([...errors].filter((e) => e !== skillsError));
    setSelectedTags(newElements);
    //@ts-ignore
    setCreateAssesmentValues?.({
      ...createAssesmentValues,
      skills: newElements.map((skill: any) => skill.value),
    });
  };

  const handleNext = () => {
    if (selectedTags.length === 0) {
      setErrors([...errors, skillsError]);
      return;
    }
    if (
      createAssesmentValues?.skills &&
      createAssesmentValues?.date &&
      createAssesmentValues?.experience_value
    ) {
      next();
    }
  };

  return (
    <React.Fragment>
      <div className="bg-white-900  py-8 rounded relative pt-0">
        <p className="font-bold text-2xl">
          {strings.create_assessment.basic_details}
        </p>
        <div className="mt-8">
          <p className="text-sm">{strings.create_assessment.tags}</p>
          <div className="mt-2">
            <Select
              isMulti
              isSearchable
              isClearable
              options={skills}
              onChange={handleTagsChange}
              closeMenuOnSelect={false}
              components={animatedComponents}
              backspaceRemovesValue
              value={selectedTags.map((tag) => tag)}
              defaultValue={selectedTags}
              theme={(theme: any) => ({
                ...theme,
                borderRadius: "6px",
                colors: {
                  ...theme.colors,
                  primary25: "#fafafa",
                  primary: AppColors.PRIMARYCOLOR,
                },
              })}
              isDisabled={reusable_assessment_details ? true : false}
            />
          </div>
          {errors.includes(skillsError) && (
            <p className="text-red-500 text-xs">{skillsError}</p>
          )}
          {selectedTags.length > 0 && (
            <p
              className={`mt-2 text-xs justify-end flex ${
                totalQuestions! < 30 ? "text-red-400" : "text-lightBlack-300"
              } `}
            >
              {`${totalQuestions || 0} - Questions (${
                createAssesmentValues?.experience_value === "1"
                  ? "Beginner"
                  : createAssesmentValues?.experience_value === "2"
                  ? "Intermeditate"
                  : "Expert"
              } level)`}
            </p>
          )}
        </div>
        <div className="mt-8">
          <p className="text-sm">{strings.create_assessment.date}</p>
          <div className="mt-2">
            <DatePicker
              onChange={(newdate: Moment | null) => {
                setAssessmentDate(newdate);
                //@ts-ignore
                setCreateAssesmentValues?.({
                  ...createAssesmentValues,
                  date: newdate,
                });
              }}
              value={assessmentDate}
              format={"DD-MM-YYYY"}
              customInputStyle={datePickerInputStyle}
              isCustomInput
              customContainerStyle={datePickerContainerStyle}
              minDate={moment()}
            />
          </div>
        </div>
        <div className="mt-8 relative">
          <p className="text-sm mb-2">{strings.experience}</p>

          <Select
            options={experienceOptions}
            id="experience"
            onChange={(evt: any) => {
              setExperienceOpt(evt);
              //@ts-ignore
              setCreateAssesmentValues?.({
                ...createAssesmentValues,
                experience_value: evt.value,
              });
            }}
            isMulti={false}
            defaultValue={experienceOpt}
            value={experienceOpt}
          />
        </div>
        <div className="mt-8 flex items-center">
          <p className="text-sm">{strings.create_assessment.public}</p>
          <div>
            <Switch
              checked={!!isOrgOnly}
              onChange={(e) => {
                setisOrg(() => (isOrgOnly ? 0 : 1));
                //@ts-ignore
                setCreateAssesmentValues?.({
                  ...createAssesmentValues,
                  switch: isOrgOnly ? 0 : 1,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full justify-end">
        <div className={styles.next_btn_wrap} onClick={handleNext}>
          <div className={styles.next_btn_before}></div>
          <button className={styles.next_button}>Next</button>
          <RightArrow className={styles.icon} size={20} />
        </div>
      </div>
    </React.Fragment>
  );
};
