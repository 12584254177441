import React from "react";
import styles from "./CollegeLandingPageStyles.module.scss";
import strings from "globals/constants/strings.json";

export const Header: React.FC = () => {
  return (
    <div className={styles.header__wrap}>
      <div className={styles.logo_wrap}>{strings.recruitbae} | CampusDrive</div>
      <div className={styles.button_wrap}>
        <button className={styles.header_button}>Contact Us</button>
      </div>
    </div>
  );
};
