import {ReactElement, createContext, useContext, useState} from "react";

export type ChatProviderProps = {
  lipAudioPlayed: boolean;
  setLipAudioPlayed: ((value: boolean) => void) | null;
  answer: string;
  setAnswer: ((value: string) => void) | null;
  isSubmitDisabled: boolean;
  setIsSubmitDisabled: ((value: boolean) => void) | null;
  onMessagePlayed: () => void;
};

const ChatContext = createContext<ChatProviderProps>({
  lipAudioPlayed: false,
  setLipAudioPlayed: null,
  answer: "",
  setAnswer: () => {},
  onMessagePlayed: () => {},
  isSubmitDisabled: true,
  setIsSubmitDisabled: () => {},
});

export const ChatProvider = ({children}: {children: ReactElement}) => {
  const [lipAudioPlayed, setLipAudioPlayed] = useState<boolean>(false);
  const [answer, setAnswer] = useState<string>("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

  const onMessagePlayed = () => {
    setLipAudioPlayed(true);
  };

  return (
    <ChatContext.Provider
      value={{
        lipAudioPlayed,
        setLipAudioPlayed,
        answer,
        setAnswer,
        onMessagePlayed,
        isSubmitDisabled,
        setIsSubmitDisabled,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};
