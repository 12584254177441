import React, {useCallback, useEffect, useRef, useState} from "react";
import {useFullScreenHandle} from "react-full-screen";
import Geocode from "react-geocode";
import cookies from "react-cookies";
import {StatusMessages, useReactMediaRecorder} from "react-media-recorder";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import moment, {Moment} from "moment";
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from "mic-check";
import {Typography} from "@mui/material";
import html2canvas from "html2canvas";
import Bowser from "bowser";
import {toast} from "react-toastify";
import {SessionUnmaintained} from "./SessionUnmaintained";
import {InterviewFlow} from "./InterviewFlow";
import {Click_Direction} from "./Question";
import {InterviewUnavailable} from "./InterviewUnavailable";
import styles from "./Interview.module.scss";

import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import Loader from "components/Loader";
import {TabSwitchedAlert} from "components/Popups/TabSwitchedAlert";
import {PermissionsChangedAlert} from "components/Popups/PermissionsChangedAlert";
import {ScreenShareStopped} from "components/Popups/ScreenShareStopped";
import {EnterFullScreenAlert} from "components/Popups/EnterFullScreenAlert";
import {ConfirmTestFinish} from "components/Popups/ConfirmTestFinish";

import {InterviewQuestionsResponse, response} from "globals/types/APIResponses";
import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import strings from "globals/constants/strings.json";
import {
  DialogType,
  InterviewQuestion,
  InterviewQuestionOption,
  QuestionOption,
} from "globals/types/globalTypes";
import {base64Toblob, getSpeechSounds} from "globals/helpers";
import {RouteLinks} from "globals/constants/routeLinks";
import {TabSwitchWarning} from "globals/constants/InterviewConstants";

import {useJWTList} from "hooks/interview/useJWTList";
import {useFetchInterviewQuestions} from "hooks/interview/useFetchInterviewQuestions";
import {useFetchUpdatedSession} from "hooks/interview/useFetchUpdatedSession";
import {useUpdateAssessment} from "hooks/interview/useUpdateAssessment";
import {useUpdateAnswer} from "hooks/interview/useUpdateAnswer";
import {useUploadScreenshot} from "hooks/interview/useUploadScreenshot";
import {useFetchAssessmentDetails} from "hooks/interview/useFetchAssessmentDetails";

const InterviewQuestions: React.FC = () => {
  const message = new SpeechSynthesisUtterance();
  const browser = Bowser.getParser(window.navigator.userAgent);
  message.text = TabSwitchWarning;

  const userLocation = useRef<string | null>(null);
  const tabSwitchCount = useRef<number>(0);
  const remainingTime = useRef<number>(1800);
  const tabSwitchStartTime = useRef<Moment | null>(null);
  const timerId = useRef<NodeJS.Timer | undefined>(undefined);
  const previousRecorderStatus = useRef<StatusMessages | null>(null);
  const saveSessionTimerId = useRef<NodeJS.Timer | undefined>(undefined);
  const trackPermissionsTimerId = useRef<NodeJS.Timer | undefined>(undefined);
  const tabSwitchMessageButtonRef = useRef<HTMLButtonElement>(null);

  const params = useParams();
  const {startRecording, stopRecording, status} = useReactMediaRecorder({
    screen: true,
    video: true,
  });
  const handle = useFullScreenHandle();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const {assessmentId} = params;
  const duration = queryParams.get("duration");

  const {
    setInterview_details,
    loading,
    permissions,
    interview_details,
    setLoading,
    setPermissions,
    setTestForcefullyFinished,
  } = useAppCommonDataProvider();
  const {refetch: fetchJWTList} = useJWTList(assessmentId!);
  const {refetch: fetchInterviewQuestions} = useFetchInterviewQuestions(
    assessmentId!
  );
  const {refetch: fetchUpdatedSession} = useFetchUpdatedSession(
    assessmentId!,
    duration!
  );
  const {mutateAsync: updateAssessment} = useUpdateAssessment(assessmentId!);
  const {mutateAsync: updateAnswer} = useUpdateAnswer(assessmentId!);
  const {mutateAsync: uploadScreenshot} = useUploadScreenshot(assessmentId!);
  const {refetch: getAssessmentDetails} = useFetchAssessmentDetails(
    assessmentId!
  );

  const [attemptingOnAnotherSystem, setAttemptingOnAnotherSystem] =
    useState<boolean>(false);
  const [interviewQuestions, setInterviewQuestions] = useState<
    InterviewQuestionsResponse | undefined
  >(response);
  const [currentQuestion, setCurrentQuestion] = useState<{
    index: number;
    question: InterviewQuestion;
  } | null>({
    index: 0,
    question: response.data.assessment.assessment_questions[0],
  });
  const [isInterviewPossible, setIsInterviewPossible] =
    useState<boolean>(false);
  const [answers, setAnswers] = useState<QuestionOption[]>([]);
  const [loaderText, setLoaderText] = useState<string>(
    strings.interview.loader_text
  );
  const [uniqueResponses, setUniqueResponses] = useState<number[]>([]);
  const [userSwitchedTabs, setUserSwitchedTabs] = useState<boolean>(false);
  const [
    countdownRemainingTimeAfterTabSwitch,
    setCountdownRemainingTimeAfterTabSwitch,
  ] = useState<number>(0);
  const [isPermissionsModalOpen, setIsPermissionsModalOpen] =
    useState<boolean>(false);
  const [permissionError, setPermissionError] = useState<DialogType | null>(
    null
  );
  const [screenShareDenied, setScreenShareDenied] = useState<boolean>(false);
  const [isTestFinished, setIsTestFinished] = useState<boolean>(false);
  const [fullScreenParameters, setFullScreenParameters] = useState<{
    didUserPrompt: boolean;
    showModal: boolean;
    changeCount: number;
  }>({
    didUserPrompt: false,
    showModal: false,
    changeCount: 0,
  });
  const [confirmTestFinish, setConfirmTestFinish] = useState<boolean>(false);

  const showDialogRef = useRef<DialogType | null>(permissionError);
  showDialogRef.current = permissionError;

  function getUserLocation() {
    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        Geocode.fromLatLng(
          position.coords.latitude.toString(),
          position.coords.longitude.toString()
        ).then((response) => {
          userLocation.current = `${response.results[0].address_components[5].short_name} (${response.results[0].address_components[6].short_name}), ${response.results[0].address_components[7].short_name}`;
        });
      },
      () => {}
    );
  }

  function getJWTList() {
    // fetchJWTList().then((res) => {
    //   const existingJWT = cookies.load(COOKIESKEYS.JWT);
    //   if (!existingJWT || res.data?.data.jwt !== existingJWT) {
    //     stopRecording();
    //     setAttemptingOnAnotherSystem(true);
    //   }
    // });
  }

  function prepareQuestions() {
    setInterviewQuestions(response);
    setCurrentQuestion({
      index: 0,
      question: response.data?.assessment.assessment_questions[0],
    });
    retrieveSession();
    setIsInterviewPossible(true);
    startRecording();
    setTimeout(() => setLoading?.(false), 1000);
  }

  function syncAnswer(options: InterviewQuestionOption[]) {
    updateAnswer({
      questionId: options[0].question_id,
      payload: {
        pivot: {status: 3},
        option_id: [...options.map((option) => option.id)],
        creatorId: cookies.load("candidateID"),
      },
    });
  }

  function retrieveSession() {
    // fetchUpdatedSession().then((res) => {
    // if (res.isError === false && res.data !== undefined) {
    // if (res.data.remainingTime !== null)
    remainingTime.current = 3000;
    //     else
    //       remainingTime.current =
    //         interview_details!.data.assessment.assessment_duration;
    //     setAnswers(res.data.answers);
    //   }
    // });
  }

  function saveSession() {
    updateAssessment({
      time_remaining: Math.floor(remainingTime.current / 60),
    }).then((res) => {
      if (res !== undefined) {
        const {attempts, jwt} = res.data.data;
        if (attempts > 4) {
          clearInterval(saveSessionTimerId.current);
          clearInterval(trackPermissionsTimerId.current);
          setIsInterviewPossible(false);
          stopRecording();
          setTimeout(() => {
            finishTest();
          }, 2000);
        } else {
          const existingJWT = cookies.load(COOKIESKEYS.JWT);
          if (existingJWT !== jwt) {
            stopRecording();
            setAttemptingOnAnotherSystem(true);
          }
        }
      }
    });
  }

  function enterFullScreen() {
    timerId.current = undefined;
    tabSwitchStartTime.current = null;
    setUserSwitchedTabs(false);
    handle.enter();
    setFullScreenParameters({
      didUserPrompt: false,
      showModal: false,
      changeCount: fullScreenParameters.changeCount + 1,
    });
  }

  const handleConfirmFinishTestPopup = () => {
    setConfirmTestFinish(true);
    setFullScreenParameters({
      changeCount: fullScreenParameters.changeCount + 1,
      didUserPrompt: true,
      showModal: false,
    });
    handle.exit();
  };

  async function finishTest() {
    const duration = cookies.load(COOKIESKEYS.DURATION);
    const elapsedTime = Math.floor(remainingTime.current / 60);
    clearInterval(saveSessionTimerId.current);
    clearInterval(trackPermissionsTimerId.current);

    setLoaderText(strings.interview.finishing_test);
    setLoading?.(true);
    setIsTestFinished(true);
    updateAssessment({
      status: 4,
      tab_switches: tabSwitchCount.current,
      time_taken: parseInt(duration) - elapsedTime,
      location: userLocation.current || "NA",
    }).then(() => {
      cookies.remove(COOKIESKEYS.DURATION, {path: "/"});
      cookies.remove(COOKIESKEYS.INTERVIEW_DETAILS, {path: "/"});
      cookies.remove(COOKIESKEYS.JWT, {path: "/"});
      cookies.remove(COOKIESKEYS.REMAINING_TIME, {path: "/"});
      cookies.remove(COOKIESKEYS.UNIQUE_RESPONSES, {path: "/"});
      cookies.remove(COOKIESKEYS.RESPONSES, {path: "/"});
      localStorage.clear();
      stopRecording();
      navigate(`/${RouteLinks.Interview_Finish}`);
    });
  }

  function startCountdown() {
    timerId.current = setInterval(() => {
      const remainingTime = moment().diff(
        tabSwitchStartTime.current,
        "seconds"
      );
      setCountdownRemainingTimeAfterTabSwitch(remainingTime);
      if (remainingTime === 45) {
        setTestForcefullyFinished?.(true);
        clearInterval(timerId.current);
        finishTest();
      }
    }, 1000);
  }

  function handleTabSwitch(e: Event) {
    if (document.visibilityState === "hidden") {
      if (tabSwitchCount.current === 4) {
        setIsTestFinished(true);
        stopRecording();
        finishTest();
      } else {
        e.preventDefault();
        tabSwitchMessageButtonRef.current?.click();
        setFullScreenParameters({
          showModal: false,
          didUserPrompt: true,
          changeCount: fullScreenParameters.changeCount,
        });
        handle.exit();
        tabSwitchCount.current += 1;
        setUserSwitchedTabs(true);
        tabSwitchStartTime.current = moment();
        startCountdown();
      }
    } else {
      e.preventDefault();
      window.speechSynthesis.cancel();
    }
  }

  function handleTabSwitchModalClose() {
    enterFullScreen();
    timerId.current = undefined;
    tabSwitchStartTime.current = null;
    setUserSwitchedTabs(false);
  }

  function checkForExplanationDialog() {
    if (
      (permissions?.camera === false || permissions?.microphone === false) &&
      showDialogRef.current === null
    )
      setPermissionError(DialogType.explanation);
  }

  function trackPermissions() {
    requestMediaPermissions()
      .then(() => {
        setIsPermissionsModalOpen(false);
        setPermissions?.({
          camera: true,
          microphone: true,
        });
      })
      .catch((e: MediaPermissionsError) => {
        setIsPermissionsModalOpen(true);
        const {type} = e;
        if (type === MediaPermissionsErrorType.SystemPermissionDenied)
          setPermissionError(DialogType.systemDenied);
        else if (type === MediaPermissionsErrorType.UserPermissionDenied)
          setPermissionError(DialogType.userDenied);
        else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource)
          setPermissionError(DialogType.trackError);
      });
    setTimeout(() => checkForExplanationDialog(), 500);
  }

  async function uploadWindowScreenshot(url: string) {
    const screenshot = await base64Toblob(url);
    const image = new File([screenshot], "screenshot.png", {
      type: screenshot.type,
    });
    const data = new FormData();
    data.append("assessment_screenshot", image);
    uploadScreenshot(data);
  }

  async function captureWindowScreenshot() {
    html2canvas(document.body)
      .then((canvas) => {
        const url = canvas.toDataURL();
        uploadWindowScreenshot(url);
      })
      .catch((err) => console.log(err, "errror in canvas"));

    // toPng(document.body)
    //   .then((url) => {
    //     uploadWindowScreenshot(url);
    //   })
    //   .catch((error) => console.log(error, "error in topng"));
  }

  const changeAnswers = (
    options: QuestionOption[] | undefined,
    direction: Click_Direction
  ) => {
    // if (options !== undefined) {
    //   if (options.length > 0) {
    //     let tempAnswers = [...answers];
    //     const answerExists = tempAnswers.filter(
    //       (answer) => answer.question_id === options[0].question_id
    //     );
    //     if (answerExists.length === 0) {
    //       options.forEach((option) => tempAnswers.push(option));
    //       setAnswers(tempAnswers);
    //     } else {
    //       tempAnswers = tempAnswers.filter(
    //         (answer) => answer.question_id !== options[0].question_id
    //       );
    //       options.forEach((option) => tempAnswers.push(option));
    //       setAnswers(tempAnswers);
    //     }
    //     if (direction === "proceed") {
    //       currentQuestion?.index! + 1 <
    //         interviewQuestions?.data.assessment.assessment_questions.length! &&
    //         setCurrentQuestion({
    //           index: currentQuestion!.index + 1,
    //           question:
    //             interviewQuestions?.data.assessment.assessment_questions[
    //               currentQuestion?.index! + 1
    //             ]!,
    //         });
    //       syncAnswer(options);
    //     }
    //   } else {
    //     const {assessment_questions} = interviewQuestions!.data.assessment;
    //     currentQuestion?.index! + 1 < assessment_questions.length! &&
    //       setCurrentQuestion({
    //         index: currentQuestion?.index! + 1,
    //         question: assessment_questions[currentQuestion?.index! + 1]!,
    //       });
    //   }
    // }
  };

  const clearSelection = (question: InterviewQuestion | undefined) => {
    // if (question !== undefined) {
    //   const responses = [...answers].filter(
    //     (response) => response.question_id !== question.question_id
    //   );
    //   setAnswers(responses);
    // }
  };

  const handleScreenShareDenied = () => {
    if (
      (previousRecorderStatus.current === "acquiring_media" &&
        status === "idle") ||
      status === "stopped"
    ) {
      setScreenShareDenied(true);
    }
    if (status === "recording") {
      setScreenShareDenied(false);
    }

    previousRecorderStatus.current = status;
  };

  const handleFullScreenChange = useCallback(
    (state: boolean) => {
      if (!state && !fullScreenParameters.didUserPrompt)
        setFullScreenParameters({
          didUserPrompt: true,
          showModal: true,
          changeCount:
            fullScreenParameters.changeCount &&
            fullScreenParameters.changeCount + 1,
        });
      else
        setFullScreenParameters({
          didUserPrompt: false,
          showModal: false,
          changeCount: fullScreenParameters.changeCount + 1,
        });
    },
    [handle]
  );

  useEffect(() => {
    const getVoices = async () => {
      const voices = await getSpeechSounds();
      if (browser.getBrowserName() === "Chrome")
        message.voice = voices.filter((v) => v.name === "Google US English")[0];
      else if (browser.getBrowserName() === "Safari") {
        message.voice = voices[43];
      }
      document.addEventListener("visibilitychange", handleTabSwitch);
    };

    const updated_at = new Date("2024-08-26T10:39:46.943Z"),
      time_remaining = 6000;

    const difference = moment().diff(moment(updated_at), "minutes");
    if (time_remaining === null || difference < time_remaining) {
      remainingTime.current = difference;

      getVoices();
      // setInterview_details?.(response.data);
      getUserLocation();
      // enterFullScreen();
      getJWTList();
      prepareQuestions();

      trackPermissionsTimerId.current = setInterval(
        () => trackPermissions(),
        2000
      );
      saveSessionTimerId.current = setInterval(() => saveSession(), 30000);

      window.onbeforeunload = () => true;
    }

    return () => {
      window.onbeforeunload = () => null;
      document.removeEventListener("visibilitychange", handleTabSwitch);
      clearInterval(saveSessionTimerId.current);
      clearInterval(timerId.current);
      clearInterval(trackPermissionsTimerId.current);
    };
  }, []);

  useEffect(() => {
    handleScreenShareDenied();
  }, [status]);

  useEffect(() => {
    // if (attemptingOnAnotherSystem === true) {
    //   stopRecording();
    //   clearInterval(saveSessionTimerId.current);
    // }
  }, [attemptingOnAnotherSystem]);

  return (
    <>
      <button
        className="hidden"
        onClick={() => window.speechSynthesis.speak(message)}
        ref={tabSwitchMessageButtonRef}
      >
        hidden
      </button>
      <InterviewFlow
        currentQuestion={currentQuestion}
        setCurrenQuestion={setCurrentQuestion}
        questions={interviewQuestions}
        answers={answers}
        uniqueResponses={uniqueResponses}
        finishTest={handleConfirmFinishTestPopup}
        onChangeAnswer={changeAnswers}
        clearSelection={clearSelection}
        isTestFinished={isTestFinished}
        remainingTime={remainingTime.current}
        onInterviewFinished={finishTest}
        updateTimer={(newtime) => (remainingTime.current = newtime)}
        fullScreenHandle={handle}
        handleFullScreenChange={handleFullScreenChange}
        captureScreenshot={captureWindowScreenshot}
      />

      {userSwitchedTabs === true && (
        <TabSwitchedAlert
          open={userSwitchedTabs}
          onClose={handleTabSwitchModalClose}
          tabSwitchCount={tabSwitchCount.current}
          remainingTime={countdownRemainingTimeAfterTabSwitch}
        />
      )}

      {isPermissionsModalOpen === true && (
        <PermissionsChangedAlert
          open={isPermissionsModalOpen}
          onClose={() => {}}
          permissionError={permissionError}
        />
      )}

      {screenShareDenied === true && (
        <ScreenShareStopped
          open={screenShareDenied}
          onClose={() => {}}
          setUserStoppedScreenShare={setScreenShareDenied}
          startRecording={startRecording}
        />
      )}

      {fullScreenParameters.didUserPrompt === true &&
        fullScreenParameters.showModal === true && (
          <EnterFullScreenAlert
            open={
              fullScreenParameters.didUserPrompt === true &&
              fullScreenParameters.showModal === true
            }
            onClose={() => {}}
            changeCount={fullScreenParameters.changeCount}
            enterFullScreen={enterFullScreen}
            finishTest={handleConfirmFinishTestPopup}
          />
        )}

      {confirmTestFinish === true && (
        <ConfirmTestFinish
          open={confirmTestFinish}
          onClose={() => {
            setConfirmTestFinish(false);
            enterFullScreen();
          }}
          finishTest={finishTest}
        />
      )}
      <p>helo</p>
    </>
  );
};

export default InterviewQuestions;
