import {AppConfig} from "appConfig";

let BASE_URL = AppConfig.Configuration.BASE_URL;
let BASE_NODE_URL = AppConfig.Configuration.BASE_NODE_URL;

export const APIEndPoints = {
  LOGIN: `${BASE_URL}/auth/login`,
  LOGIN_NODE: `${BASE_NODE_URL}/auths/login`,
  ASSESMENT: `${BASE_URL}/assesments`,
  ASSESMENT_UPDATE_PIVOT: (assessmentId: number, questionId: number) =>
    `${BASE_NODE_URL}/assesments/update-pivot/${assessmentId}/${questionId}`,
  ASSESMENT_NODE: `${BASE_NODE_URL}/assesments/create`,
  CANDIDATE: `${BASE_URL}/candidate`,
  GET_SKILLS: `${BASE_URL}/skill`,
  GET_SKILLS_NODE: `${BASE_NODE_URL}/skills/all`,
  QUESTIONS: `${BASE_URL}/question`,
  QUESTIONS_NODE: (id: string) => `${BASE_NODE_URL}/questions/create/${id}`,
  GET_PUBLIC_QUESTIONS: (skillId: string) =>
    `${BASE_URL}/questions/public/${skillId}`,
  GET_ASSESSMENTS: `${BASE_NODE_URL}/assesments/search`,
  GET_ASSESSMENTS_NODE: `${BASE_NODE_URL}/assesments/searchAssesment`,
  GET_EXPIRED_ASSESSMENTS_NODE: `${BASE_NODE_URL}/assesments/expired-assessment`,
  UPLOAD_SESSION_RECORDING: `${BASE_URL}/candidate`,
  UPLOAD_SESSION_RECORDING_NODE: (id: string) =>
    `${BASE_NODE_URL}/assesments/upload-large-file/${id}`,
  GET_INTERVIEW_INSTRUCTIONS: `${BASE_NODE_URL}/assesments/assessment-detail-by-id`,
  GET_BEHAVIOR_QUESTIONS: (id: string) =>
    `${BASE_NODE_URL}/assesments/assessment-behaviour-detail-by-id/${id}`,
  CREATE_ASSESSMENT_TEMPLATE: `${BASE_URL}/assessment-template`,
  CREATE_ASSESSMENT_TEMPLATE_NODE: `${BASE_NODE_URL}/assesment-templates/create`,
  GET_ASSESSMENT_TEMPLATE_NODE: `${BASE_NODE_URL}/assesment-templates/all`,
  CREATE_BULK_ASSESSMENTS: `${BASE_NODE_URL}/assesments/upload-csv`,
  CANDIDATE_WITH_DETAILS: `${BASE_URL}/candidate/search?include=user,resume,assessments`,
  CANDIDATE_WITH_DETAILS_NODE: `${BASE_NODE_URL}/candidates/searchCandidate`,
  GET_ASSESSMENT_TEMPLATES: `${BASE_URL}/assessment-template/search`,
  GET_ASSESSMENT_TEMPLATES_NODE: `${BASE_NODE_URL}/assesment-templates/searchAssesmentTemplate`,
  GET_ALL_QUESTIONS: `${BASE_URL}/question/search?include=skill,createdBy,options,creator`,
  GET_ALL_QUESTIONS_NODE: `${BASE_NODE_URL}/questions/all`,
  GET_SKILL_WITH_QUE_COUNT: `${BASE_URL}/skill/search?include=questions&question_count=1`,
  GET_SKILL_WITH_QUE_COUNT_NODE: `${BASE_NODE_URL}/skills/search`,
  BATCH_UPLOAD_QUESTION: `${BASE_URL}/question/batch`,
  UPLOAD_CSV_NODE: `${BASE_NODE_URL}/questions/upload`,
  // UPLOAD_CSV: `${BASE_URL}/question/import`,
  RESET_PASSWORD: `${BASE_URL}/auth/reset-password`,
  RESET_PASSWORD_NODE: `${BASE_NODE_URL}/auths/reset-password`,
  FORGOT_PASSWORD: `${BASE_URL}/auth/forgot-password`,
  FORGOT_PASSWORD_NODE: `${BASE_NODE_URL}/auths/forgot-password`,
  UPDATE_PASSWORD_NODE: `${BASE_NODE_URL}/auths/update-password`,
  GET_PENDING_STATUS_ASSESSMENTS: `${BASE_NODE_URL}/assesments/all`,
  ALL_INTERVIEWS: `${BASE_URL}/assessment/search?`,
  CANDIDATE_LOGIN: `${BASE_URL}/candidate-login`,
  CANDIDATE_LOGIN_NODE: `${BASE_NODE_URL}/candidates/login`,
  RESCHEDULE_ASSESSMENT: (assessment_id: string) =>
    `${BASE_NODE_URL}/assesments/reschedule-assessment/${assessment_id}`,

  ALL_INTERVIEWS_NODE: `${BASE_NODE_URL}/assesments/allInterviewsWithFilter`,
  CANDIDATE_DELETE: (id: string) => `${BASE_URL}/candidate/${id}`,
  SEND_SCREENSHOT: (id: string) => `${BASE_NODE_URL}/assesments/file/${id}`,
  CSVFILTER_QUESTIONS: (params: string | undefined) =>
    `${BASE_URL}/questions/get-filtered-questions/${params}`,
  ASSESMENT_OF_ID: (id: string | number) => `${BASE_URL}/assessment/${id}`,
  ASSESMENT_NODE_OF_ID: (id: string | number) =>
    `${BASE_NODE_URL}/assesments/delete/${id}`,
  // UPDATE_ASSESMENT: (id: string | number) => `${BASE_URL}/assessment/${id}`,
  UPDATE_QUESTION: (id: string) => `${BASE_URL}/question/${id}?_method=PATCH`,
  UPDATE_QUESTION_NODE: (id: string) =>
    `${BASE_NODE_URL}/questions/update/${id}`,
  DELETE_QUESTION: (id: string | number | undefined) =>
    `${BASE_URL}/question/${id}`,
  DELETE_QUESTION_NODE: (id: string | number | undefined) =>
    `${BASE_NODE_URL}/questions/delete/${id}`,
  QUESTION_OF_ID: (id: string | number) =>
    `${BASE_URL}/question/${id}?include=options,image,skill`,
  QUESTION_OF_ID_NODE: (id: string) =>
    `${BASE_NODE_URL}/questions/questionById/${id}`,
  CANDIDATE_OF_ID: (id: string) =>
    `${BASE_URL}/candidate/${id}?include=assessments,user,resume`,
  CANDIDATE_OF_ID_NODE: (id: string) =>
    `${BASE_NODE_URL}/candidates/findCandidateById/${id}`,
  GET_DASHBOARD: (number?: string) =>
    `${BASE_URL}/dashboard${number ? `?number_of_days=${number}` : ""}`,
  GET_DASHBOARD_NODE: (number?: string) =>
    `${BASE_NODE_URL}/assesments/dashboard${
      number ? `?number_of_days=${number}` : ""
    }`,
  ASSESMENT_WITH_DETAILS: (id: string | number) =>
    `${BASE_URL}/assessment/${id}?include=organization,questions,skill,questions.options,creator,questions.skill,questions.createdBy`,
  CANDIDATE_DETAILS: (candidateId: number | undefined, assessmentId: number) =>
    `${BASE_URL}/candidate/${candidateId}/assessment/${assessmentId}?include=screenshots.file`,
  CANDIDATE_DETAILS_NODE: (assessmentId: number | string) =>
    `${BASE_NODE_URL}/assesments/assessment-detail-by-id/${assessmentId}`,
  COMMUNICATION_SKILL_SCORE: (
    candidateId: number | string,
    status: number | string
  ) =>
    `${BASE_NODE_URL}/candidates/update_communication/${candidateId}/${status}`,
  NOTIFY_RESULT: (candidateId: number | undefined, assessmentId: number) =>
    `${BASE_URL}/candidate/${candidateId}/assessments/${assessmentId}/notify-result`,
  // GENERATE_REPORT: (candidateId: number | undefined, assessmentId: number) =>
  //   `${BASE_URL}/candidate/${candidateId}/assessments/${assessmentId}/download`,
  GENERATE_REPORT: (assessmentId: number) =>
    `${BASE_NODE_URL}/assesments/${assessmentId}/generate-pdf`,
  // UPDATE_SESSION_TIME_REMAINING: (assessmentId: string) =>
  // `${BASE_NODE_URL}/assessment/${assessmentId}/update-time-remaining`,
  GET_UPDATED_SESSION: (assessmentId: string) =>
    `${BASE_NODE_URL}/assesments/get-assessment-responses/${assessmentId}`,
  UPDATE_IP_SESSION_TIME_REMAINING_ASSESMENT: (
    assessmentId: string | undefined
  ) => `${BASE_NODE_URL}/assesments/update/${assessmentId}`,
  UPDATE_JWT: (assessmentId: string) =>
    `${BASE_NODE_URL}/assesments/updateJwt/${assessmentId}`,
  GET_JWTS: (assessmentId: string) =>
    `${BASE_NODE_URL}/assesments/getJwt/${assessmentId}`,
  GET_ALL_USERS: `${BASE_NODE_URL}/users/all`,
  GET_ALL_ROLES: `${BASE_NODE_URL}/roles/all`,
  ASSIGN_ROLE: `${BASE_NODE_URL}/roles/assign_role`,
  UPDATE_USER_STATUS: (status: number, user_id: number) =>
    `${BASE_NODE_URL}/users/update/${user_id}/${status}`,
  DELETE_USER: (user_id: number) => `${BASE_NODE_URL}/users/delete/${user_id}`,
  CREATE_USER: `${BASE_NODE_URL}/users/create`,
  GET_ANSWERS: (assessmentId: string | number) =>
    `${BASE_NODE_URL}/assesments/questions_detail_of_assessment/${assessmentId}`,

  UPDATE_BEHAVIOUR_QUESTION_RESPONSE: (
    assessmentId: string,
    questionId: string
  ) =>
    `${BASE_NODE_URL}/assesments/save-behaviour-response/${assessmentId}/${questionId}`,
  GET_BEHAVIOUR_SESSION: (assessmentId: string) =>
    `${BASE_NODE_URL}/assesments/get-assessment-behaviour-responses/${assessmentId}`,
  GET_RESUME_DATA: (assessmentId: string) =>
    `${BASE_NODE_URL}/assesments/assessment-resume-detail-by-id/${assessmentId}`,
  SAVE_SECOND_ROUND_RESPONSE: (assessmentId: string, questionId: string) =>
    `${BASE_NODE_URL}/assesments/save-round-two-answers/${assessmentId}/${questionId}`,
  GET_SECOND_ROUND_RESPONSES: (assessmentId: string) =>
    `${BASE_NODE_URL}/assesments/get-round-two-answers/${assessmentId}`,
  PARSE_RESUME: `${BASE_NODE_URL}/candidates/extract_text_from_pdf`,
  SAVE_LEADS: `${BASE_NODE_URL}/leads/create`,
};
