import React from "react";
import styles from "./CollegeLandingPageStyles.module.scss";
import {CTA} from "./CTA";
import {Footer} from "./Footer";
import {Header} from "./Header";
import {HeroSection} from "./HeroSection";
import {OurCandidate} from "./OurCandidate";
import {OurClient} from "./OurClient";
import {WhoWeAre} from "./WhoWeAre";

export const CollegeLandingPage: React.FC = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <WhoWeAre />
      <OurClient />
      <OurCandidate />
      <CTA />
      <Footer />
    </div>
  );
};
