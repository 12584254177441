import React from "react";
import {CSSProperties} from "@emotion/serialize";
import Select from "react-select";
import {components} from "react-select";
import makeAnimated from "react-select/animated";
import {AppColors} from "globals/appcolors";
import {SelectStyles} from "globals/constants/styles";

const animatedComponents = makeAnimated();

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

export default function CustomSelect({
  options,
  defaultValue,
  onChange,
  isMulti = false,
  customStyles = {},
  isAnimated = false,
  placeholder,
  value,
  isClearable,
  isDisabled,
  customComponent,
}: any) {
  return (
    <Select
      value={value}
      closeMenuOnSelect={isMulti ? false : true}
      hideSelectedOptions={false}
      options={options}
      isDisabled={isDisabled}
      onChange={(e) => onChange(e)}
      defaultValue={options?.filter((option: any) =>
        isMulti
          ? defaultValue?.includes(option?.value)
          : option?.value == defaultValue
      )}
      components={
        isMulti &&
        (isAnimated ? customComponent ?? animatedComponents : {Option})
      }
      isSearchable
      isMulti={isMulti}
      theme={(theme: any) => ({
        ...theme,
        borderRadius: "6px",
        colors: {
          ...theme.colors,
          primary25: "#fafafa",
          primary: AppColors.PRIMARYCOLOR,
        },
      })}
      isClearable={isClearable}
      placeholder={placeholder ?? "Select..."}
      styles={customStyles ?? SelectStyles}
      menuPortalTarget={document.body}
    />
  );
}
