import React, {useState} from "react";
import styles from "./SignUpStyles.module.scss";
import {Formik} from "formik";
import * as yup from "yup";
import {SignupCreatePasswordFormValues} from "globals/types/globalTypes";
import {Eye, EyeHidden} from "components/Icons";

let initialValues: SignupCreatePasswordFormValues = {
  create_password: "",
  re_enter_password: "",
};
const schema = yup.lazy((v) =>
  yup.object().shape({
    create_password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    re_enter_password: yup
      .string()
      .required("Password is required")
      .test("confirmPassword", "Password should be same!", () => {
        return v.password === v.confirmPassword;
      }),
  })
);

export const CreatePasswordForm: React.FC = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordInput(e.target.value);
  };
  const togglePassword = (e: any) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
    } else setPasswordType("password");
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(data) => {
          // handleThirdFormClick(data);
        }}
        validationSchema={schema}
      >
        {({values, handleSubmit, handleChange, errors, status, touched}) => {
          return (
            <form action="" className={styles.signup__forms}>
              <div className={`${styles.form__field__wrap} relative`}>
                <label htmlFor="">
                  Create Password <span className="text-red-300">*</span>
                </label>
                <input
                  type={passwordType}
                  onChange={(e) => {
                    handlePasswordChange(e);
                    handleChange;
                  }}
                  value={passwordInput}
                  name="password"
                  className="form-control"
                  placeholder="Password"
                />
                <div className="input-group-btn absolute right-4 top-12">
                  <button
                    className="btn btn-outline-primary"
                    onClick={togglePassword}
                  >
                    {passwordType === "password" ? (
                      <Eye size={23} />
                    ) : (
                      <EyeHidden size={23} />
                    )}
                  </button>
                </div>
                {errors.create_password && touched.create_password && (
                  <p className={styles.error__text}>
                    {errors?.create_password}
                  </p>
                )}
              </div>
              <div className={`${styles.form__field__wrap} relative`}>
                <label htmlFor="">
                  Re-Enter Password <span className="text-red-300">*</span>
                </label>
                <input
                  placeholder="Re-Enter Password"
                  type={passwordType}
                  onChange={handleChange}
                />
                {errors.re_enter_password && touched.re_enter_password && (
                  <p className={styles.error__text}>
                    {errors?.re_enter_password}
                  </p>
                )}
                <div className="input-group-btn absolute right-4 top-12">
                  <button
                    className="btn btn-outline-primary"
                    onClick={togglePassword}
                  >
                    {passwordType === "password" ? (
                      <Eye size={23} />
                    ) : (
                      <EyeHidden size={23} />
                    )}
                  </button>
                </div>
              </div>

              <div className=" flex flex-col justify-center w-full items-center">
                <div className="flex md:w-30 my-8">
                  <input type="checkbox" className={styles.form__checkbox} />
                  <p className="px-4 text-xs font-normal text-lightBlack-900 capitalize">
                    i’ve confirm that i have read and agree the
                    <span
                      className="text-blue-800
              "
                    >
                      {" "}
                      terms and condition
                    </span>{" "}
                    of souecebae
                  </p>
                </div>
                <button
                  className="text-white-900 font-bold  py-3 w-146px rounded-md bg-primary"
                  id="create-password"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
