import FAQ from "./components/FAQ/FAQ";
import Footer from "./components/Footer";
import GetHired from "./components/GetHired";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Hiring from "./components/Hiring";
import JoinJourney from "./components/JoinJourney";
import Technologies from "./components/TechnologiesLooper/Technologies";
import Testimonials from "./components/Testimonals";
import WhySourcebae from "./components/WhySourcebae";

const LandingPageV2 = () => {
  return (
    <div
      className={
        "font-Glimer last-screen:mx-auto last-screen:flex last-screen:justify-center "
      }
    >
      <div className=" xl:hidden ">
        <Header />
      </div>
      <div className="flex xl:hidden flex-col items-center justify-center h-[70vh] ">
        please open this page on desktop/laptop
      </div>
      <div className="max-w-[2000px] hidden  xl:block">
        <Header />
        <Hero />
        <Hiring />
        <GetHired />
        <Testimonials />
        <WhySourcebae />
        <JoinJourney />
        {/* <Hero /> */}

        <Technologies />
        <FAQ />
        <Footer />
      </div>
    </div>
  );
};

export default LandingPageV2;
