import {useMutation, useQueryClient} from "@tanstack/react-query";
import {candidateInstance} from "globals/axiosConstants";
import {APIEndPoints} from "globals/constants/endPoints";

export function useUpdateSecondRoundResponse(assessmentId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["saveSecondRoundResponse"],
    mutationFn: async ({
      answer,
      questionId,
    }: {
      answer: string;
      questionId: string;
    }) => {
      return candidateInstance
        .post(
          `${APIEndPoints.SAVE_SECOND_ROUND_RESPONSE(
            assessmentId,
            questionId
          )}`,
          {
            answer,
          }
        )
        .then(() => {
          queryClient.invalidateQueries(["saveSecondRoundResponse"]);
        });
    },
    onError: (e) => e,
  });
}
