import QuestionBanner from "./questionBanner.svg";
import EmptyQuestions from "./EmptyQuestions.svg";
import OutlinedFlag from "./outlinedFlag.svg";
import NotFound from "./404NotFound.gif";
import Recording from "./recording.svg";
import EditIcon from "./editIcon.svg";
import loader2 from "./loader2.gif";
import Sandbox from "./sandbox.gif";
import Dustbin from "./dustbin.svg";
import Search from "./Search.svg";
import Cross from "./cross.svg";
import bottomBg from "./bottom-bg-gen-pass.svg";
import logo from "./sourcebae-cropped.svg";
import LogoWithoutText from "./logoWithoutText.svg";
import loginBg from "./login-bg.svg";
import ViewEye from "./viewEye.svg";
import BackArrow from "./back-arrow.svg";
import ProfilePlaceholder from "./profile_placeholder.png";
import InterviewInstruction from "./Interview_instruction.svg";
import InterviewFlash from "./InterviewFlash.svg";
import InterviewFinish from "./ThankYouScreen.svg";
import NoAssessments from "./no_assessments.svg";
import NewNoAssessments from "./new_no_assessment.svg";
import NoTemplates from "./no_templates.svg";
import SourceBaeLogo from "./logo.svg";
import HeroSectionCircleWithGirl from "./LandingPage/HeroSectionCircleWithGirl.svg";
import InterviewVideoBg from "./InterviewIntruction/interview-video-bg.png";
import TimerIcon from "./InterviewIntruction/timer.svg";
import RecordingIcon from "./InterviewIntruction/recording-icon.svg";
import CallIcon from "./InterviewIntruction/call-icon.svg";

//LANDING PAGE IMAGES
import HeroSectionDots from "./LandingPage/hero__section__dots.svg";
import Sequoia from "Images/LandingPage/sequoia.svg";
import Innovationm from "Images/LandingPage/innovationm.svg";
import Innowrap from "Images/LandingPage/innowrap.svg";
import Torinit from "Images/LandingPage/torinit.svg";
import SmartData from "Images/LandingPage/smartData.svg";
import Srijan from "Images/LandingPage/srijan.svg";
import Semicircle from "./LandingPage/semicircle.svg";
import Timer from "./LandingPage/timer.svg";
import DirectionPointersLeft from "./LandingPage/direction_pointers_left.svg";
import DirectionPointersRight from "./LandingPage/direction_pointers_right.svg";
import FlowCardOne from "./LandingPage/flow_card_1.svg";
import FlowCardTwo from "./LandingPage/flow_card_2.svg";
import FlowCardThree from "./LandingPage/flow_card_3.svg";
import ValueEffertless from "./LandingPage/valueIcon/Astroundingly.svg";
import ValueCost from "./LandingPage/valueIcon/cost.svg";
import ValueQualified from "./LandingPage/valueIcon/qualified.svg";
import ValueSupport from "./LandingPage/valueIcon/support.svg";
import ValueSync from "./LandingPage/valueIcon/sync.svg";
import ValueTrickey from "./LandingPage/valueIcon/Trickery.svg";
import Document from "./LandingPage/document.svg";
import People from "./LandingPage/people.svg";
import hiringProcess from "Images/LandingPage/hiring_process.svg";
import CTAPoster from "./LandingPage/CTA-Poster.svg";
import HeroAssesmentCardBgFirst from "./LandingPage/first_bg.svg";
import HeroAssesmentCardBgSecond from "./LandingPage/second_bg.svg";
import WhiteLogo from "./LandingPage/white_logo.svg";
import FooterBottomLogo from "./LandingPage/recuritbae-footer-bottom.svg";

//college landing page
import heroBanner from "./collegeLandingPage/clg_hero_banner.svg";
import whoWeAre from "./collegeLandingPage/who_we_are.svg";
import collegeCTA from "./collegeLandingPage/cta_background.svg";
import ourCandidate from "./collegeLandingPage/ourCandidate.svg";

//candidate background
import greenCandidateBG from "./collegeLandingPage/candidate/green.svg";
import pinkCandidateBG from "./collegeLandingPage/candidate/pink.svg";
import yellowCandidateBG from "./collegeLandingPage/candidate/yellow.svg";
import blueCandidateBG from "./collegeLandingPage/candidate/blue.svg";
import candidate from "./collegeLandingPage/candidate/candidate.svg";

import BehaviourIcon from "./behaviourTestIcon.png";
import StepsForAssessment from "./steps-assessment.png";
import StepsForIntro from "./steps-intro.png";

// landing page client logos
import Adobe from "./LandingPage/clientLogos/adobe.svg";
import Comviva from "./LandingPage/clientLogos/comviva.svg";
import Dunzo from "./LandingPage/clientLogos/dunzo.svg";
import News24 from "./LandingPage/clientLogos/new24.svg";
import Saregama from "./LandingPage/clientLogos/saregama.svg";
import Phonepe from "./LandingPage/clientLogos/phonepe.svg";
import Paytm from "./LandingPage/clientLogos/paytm.svg";
import Apollo from "./LandingPage/clientLogos/apollo.svg";
import Pepperfry from "./LandingPage/clientLogos/pepperfry.svg";

import BenefitBottom from "./LandingPage/benefits-bottom.svg";
import BenefitBgCircle from "./LandingPage/benefit-bg.svg";
import ProfoundUnderstanding from "./LandingPage/profound-understanding.svg";
import HowWork1 from "./LandingPage/how-work-1.svg";
import HowWork2 from "./LandingPage/how-work-2.svg";
import HowWork3 from "./LandingPage/how-work-3.svg";
import IndividualsCard1 from "./LandingPage/Individuals-1.svg";
import IndividualsCard2 from "./LandingPage/Individuals-2.svg";
import IndividualsCard3 from "./LandingPage/Individuals-3.svg";
import IndividualsCard4 from "./LandingPage/Individuals-4.svg";

//REPORT IMAGES

export const Images = {
  EmptyQuestions,
  QuestionBanner,
  OutlinedFlag,
  Recording,
  EditIcon,
  NotFound,
  Dustbin,
  loader2,
  Search,
  Cross,
  bottomBg,
  logo,
  LogoWithoutText,
  loginBg,
  ViewEye,
  BackArrow,
  ProfilePlaceholder,
  InterviewFlash,
  InterviewFinish,
  NoAssessments,
  NewNoAssessments,
  NoTemplates,
  SourceBaeLogo,
  InterviewInstruction,
  InterviewVideoBg,
  TimerIcon,
  RecordingIcon,
  CallIcon,
  Sandbox,
  //LANDING PAGE EXPORTS
  WhiteLogo,
  HeroSectionDots,
  HeroSectionCircleWithGirl,
  Sequoia,
  Innovationm,
  Innowrap,
  Torinit,
  SmartData,
  Srijan,
  Semicircle,
  Timer,
  DirectionPointersLeft,
  DirectionPointersRight,
  FlowCardOne,
  FlowCardTwo,
  FlowCardThree,
  ValueSupport,
  Document,
  People,
  hiringProcess,
  CTAPoster,
  HeroAssesmentCardBgFirst,
  HeroAssesmentCardBgSecond,
  Screen,
  ValueEffertless,
  ValueCost,
  ValueQualified,
  ValueSync,
  ValueTrickey,
  BehaviourIcon,
  StepsForAssessment,
  StepsForIntro,
  FooterBottomLogo,

  //college landing page
  heroBanner,
  whoWeAre,
  collegeCTA,
  ourCandidate,

  //candidate background
  greenCandidateBG,
  pinkCandidateBG,
  yellowCandidateBG,
  blueCandidateBG,
  candidate,

  //landing page client logos
  Adobe,
  Comviva,
  Dunzo,
  News24,
  Saregama,
  Phonepe,
  Paytm,
  Apollo,
  Pepperfry,
  BenefitBottom,
  BenefitBgCircle,
  ProfoundUnderstanding,
  HowWork1,
  HowWork2,
  HowWork3,
  IndividualsCard1,
  IndividualsCard2,
  IndividualsCard3,
  IndividualsCard4,
};
