import {StyleSheet, Font, Link} from "@react-pdf/renderer";
import {AppColors} from "globals/appcolors";

import MulishLight from "./fonts/Mulish-Light.ttf";
import MulishRegular from "./fonts/Mulish-Regular.ttf";
import MulishBold from "./fonts/Mulish-Bold.ttf";
import MulishBlack from "./fonts/Mulish-Black.ttf";
import MulishMedium from "./fonts/Mulish-Medium.ttf";
import MulishSemibold from "./fonts/Mulish-SemiBold.ttf";

Font.register({
  family: "Mulish",
  fonts: [
    {
      src: MulishLight,
      fontWeight: 300,
    },
    {
      src: MulishRegular,
      fontWeight: 400,
    },
    {
      src: MulishMedium,
      fontWeight: 500,
    },
    {
      src: MulishSemibold,
      fontWeight: 600,
    },
    {
      src: MulishBold,
      fontWeight: 700,
    },
    {
      src: MulishBlack,
      fontWeight: 900,
    },
  ],
  format: "truetype",
});

export const reportCardStyles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
  },

  page: {
    backgroundColor: "#F5F5F5",
    height: "100vh",
    paddingHorizontal: 40,
    paddingVertical: 20,
  },

  section: {
    marginTop: 30,
  },

  lightText: {
    fontWeight: 300,
    fontFamily: "Mulish",
    color: "#192023",
    fontSize: 10,
  },

  centeredText: {
    textAlign: "center",
  },

  titleText: {
    color: "#192023",
    fontWeight: 500,
    fontFamily: "Mulish",
    fontSize: 15,
  },

  descriptiveText: {
    color: "#192023",
    fontSize: 10,
    fontWeight: 300,
    fontFamily: "Mulish",
    marginTop: 4,
  },

  heading: {
    fontWeight: "bold",
    fontSize: 11,
    fontFamily: "Mulish",
  },

  name: {
    fontWeight: "bold",
    fontFamily: "Mulish",
    fontSize: 18,
  },

  title: {
    color: "#192023",
    marginTop: 2,
    fontSize: 12,
    opacity: 0.6,
  },
});
