import React, {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";
import stylesCss from "./style.module.scss";

import styles from "components/styles.module.scss";
import {Row} from "components/Row";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {
  AddCircleOutline,
  LeftArrow,
  RemoveCircleOutline,
  RightArrow,
} from "components/Icons";
import Loader from "components/Loader";

import strings from "globals/constants/strings.json";
import {
  CreateAsseessmentFields,
  CreateAssessmentTemplate,
  NewCandidateExperience,
  WorkExperienceFormValues,
} from "globals/types/globalTypes";
import {useCreateAssessment} from "hooks/assessment/useCreateAssessment";
import {deleteAssessmentTest} from "globals/helpers";
import axiosGetModule from "network/Get/AxiosGetModule";
import {APIEndPoints} from "globals/constants/endPoints";
import axiosPostModule from "network/Post/AxiosPostModule";
import S3Client from "react-aws-s3-typescript";
import {s3Config} from "globals/constants/s3Config";

interface IProps {
  next: () => void;
  back: () => void;
}

export const WorkExperienceDetails: React.FC<IProps> = ({back, next}) => {
  const s3Client = new S3Client(s3Config);

  const [candidate__experience, setCandidate__experience] =
    useState<NewCandidateExperience>({
      projects: [{project_name: "", project_description: ""}],
    });
  const [loading, setLoading] = useState<boolean>(false);

  const {createAssesmentValues, setCreateAssesmentValues} =
    useAppCommonDataProvider();
  const {mutateAsync: createAssessment} = useCreateAssessment(
    false,
    setLoading
  );

  const initialValues: WorkExperienceFormValues = {
    experience: createAssesmentValues?.years_of_experience || "",
    number_of_questions: createAssesmentValues?.questions || "60",
    projectDescription: "",
    projectName: "",
  };

  const validateSchema = Yup.object().shape({
    experience: Yup.number().required("experience is required"),
    number_of_questions: Yup.string().required(
      "Number of questions is required"
    ),
  });

  const createTemplate = async (payload: CreateAssessmentTemplate) => {
    return await axiosPostModule(
      APIEndPoints.CREATE_ASSESSMENT_TEMPLATE_NODE,
      payload
    );
  };

  const submitForm = async () => {
    if (createAssesmentValues !== null) {
      setLoading(true);
      const userOrgId: any = JSON.parse(localStorage.getItem("USER_DETAILS")!);

      const allSkills = await axiosGetModule(
        APIEndPoints.GET_SKILL_WITH_QUE_COUNT_NODE
      );
      const templateName = allSkills.data.find(
        (skill: any) => skill.id === createAssesmentValues?.skills[0]
      ).skill;

      const templatePayload: CreateAssessmentTemplate = {
        organization_id: userOrgId.user.teams[0].id,
        name: templateName,
        skill_ids: createAssesmentValues?.skills,
      };

      const {data} = await createTemplate(templatePayload);

      try {
        const {
          first_name,
          last_name,
          date,
          experience_value,
          candidate_source,
          email,
          resume,
          skills,
          years_of_experience,
          questions,
          project_info,
          switch: orgOnlyQuestions,
          is_contractual,

          // new keys for contractual hiring
          include_intro,
          include_round_one,
          include_round_two,
        } = createAssesmentValues;

        let formData = new FormData();
        formData.append("file", resume);

        const {status, location: resume_url} = await s3Client.uploadFile(
          resume,
          `${first_name}-${last_name}-resume`
        );

        if (status === 204) {
          const payload: CreateAsseessmentFields = {
            resume_url,
            assessment_template_id: data.id.toString(),
            scheduled_date: date!.format("YYYY-MM-DD"),
            candidate_name: first_name,
            candidate_last_name: last_name,
            email,
            experience_duration: years_of_experience,
            number_of_questions: questions,
            experience_level: experience_value,
            source: candidate_source,
            include_organization_questions: orgOnlyQuestions.toString(),
            organization_id: userOrgId.user.teams[0].organization.id,
            skills_ids: skills.map((skill) => skill),
            project_title:
              project_info !== undefined
                ? project_info.map((project) =>
                    project.project_name !== "" ? project.project_name : "NA"
                  )
                : ["AAA"],
            project_description:
              project_info !== undefined
                ? project_info.map((project) =>
                    project.project_description !== ""
                      ? project.project_description
                      : "NA"
                  )
                : ["AAA"],
            is_contractual,
            include_intro,
            include_round_one,
            include_round_two,
          };

          createAssessment(payload).then((res) => {
            if (res !== undefined) {
              if (res.data.success === true) {
                console.log(payload, "pauyloaadd");
                toast.success("Assessment Created", {
                  position: "bottom-left",
                });
                setLoading(false);
                next();
              }
            }
          });
        }
      } catch (e) {
        setLoading(false);
        next();
        toast.error(
          "We are sorry this assessment could not be created because of a technical error on our end."
        );
        deleteAssessmentTest(data.id);
      }
    }
  };

  const addOrRemoveField = (action: "add" | "remove", index?: number) => {
    const values: NewCandidateExperience = {
      ...candidate__experience,
      projects: [...candidate__experience.projects],
    };

    if (action === "add") {
      values.projects.push({
        project_name: "",
        project_description: "",
      });
    } else if (action === "remove") {
      if (index) {
        const temp = values.projects
          .slice(0, index)
          .concat(values.projects.slice(index + 1));
        values.projects = temp;
        setCandidate__experience(values);
      }
    }
    setCandidate__experience(values);
  };

  const handleChangeForProjects = (
    value: string,
    index: number,
    field: "projectName" | "projectDescription",
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const values: NewCandidateExperience = {
      ...candidate__experience,
      projects: [...candidate__experience.projects],
    };
    values.projects[index] = {
      project_name:
        field === "projectName" ? value : values.projects[index].project_name,
      project_description:
        field === "projectDescription"
          ? value
          : values.projects[index].project_description,
    };
    setCandidate__experience(values);
    setFieldValue(field, values.projects);
    //@ts-ignore
    setCreateAssesmentValues({
      ...createAssesmentValues,
      project_info: values.projects,
    });
  };

  useEffect(() => {
    if (createAssesmentValues?.project_info !== undefined) {
      setCandidate__experience({
        ...candidate__experience,
        projects:
          createAssesmentValues.project_info !== undefined
            ? createAssesmentValues.project_info.length > 0
              ? createAssesmentValues.project_info
              : []
            : [],
      });
    }

    //@ts-ignore
    setCreateAssesmentValues?.({
      ...createAssesmentValues,
      questions:
        createAssesmentValues?.questions === undefined
          ? "60"
          : createAssesmentValues.questions,
      years_of_experience:
        createAssesmentValues?.years_of_experience === undefined
          ? ""
          : createAssesmentValues?.years_of_experience,
    });
  }, []);

  return loading === true ? (
    <Loader />
  ) : (
    <section className="mt-4">
      <div>
        <Typography variant="h5" fontWeight={"bold"}>
          {strings.new__candidate__experience.title}
        </Typography>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validationSchema={validateSchema}
      >
        {({values, errors, handleSubmit, touched, setFieldValue}) => (
          // @ts-ignore
          <Form onSubmit={handleSubmit}>
            <section
              className={`${styles.newcandidate__experienceDetail} pl-0 max-w-full`}
            >
              <Row>
                <div className="flex flex-col flex-1">
                  <Typography>
                    {strings.new__candidate__experience.total_experience}
                  </Typography>
                  <input
                    onChange={(e) => {
                      if (isNaN(+e.target.value) === true) return;
                      setFieldValue("experience", e.target.value);
                      //@ts-ignore
                      setCreateAssesmentValues?.({
                        ...createAssesmentValues,
                        years_of_experience: e.target.value,
                      });
                    }}
                    value={values.experience}
                    className={styles.input}
                    placeholder="Experience in years"
                    maxLength={2}
                  />
                  {errors.experience && touched.experience && (
                    <span className="text-red-500 text-xs mt-2">
                      {errors.experience}
                    </span>
                  )}
                </div>

                <div className="flex-1 flex flex-col">
                  <Typography className={`font-normal text-sm`}>
                    {strings.new__candidate__experience.question_nos}
                    <span className="text-red-500">*</span>
                  </Typography>
                  <input
                    name="question"
                    id="question"
                    onChange={(e) => {
                      if (isNaN(+e.target.value) === true) return;
                      setFieldValue("number_of_questions", e.target.value);
                      //@ts-ignore
                      setCreateAssesmentValues?.({
                        ...createAssesmentValues,
                        questions: e.target.value,
                      });
                    }}
                    value={values.number_of_questions}
                    className={styles.input}
                    maxLength={3}
                  />
                </div>
              </Row>
            </section>

            <div className="mt-8">
              <Typography
                variant="h6"
                fontWeight={"bold"}
                className={styles.newcandidate__experienceTitle}
              >
                {strings.new__candidate__experience.secondary__title}
              </Typography>
            </div>
            {candidate__experience.projects.map((project, index) => (
              <div
                className={styles.newcandidate__experienceDetail}
                key={index}
              >
                <div className="flex items-center">
                  <h3 className="text-sm">Project name</h3>
                  <span
                    className={`ml-1 ${
                      index === 0 ? "hidden" : ""
                    } cursor-pointer`}
                    onClick={() => addOrRemoveField("remove", index)}
                  >
                    <RemoveCircleOutline />
                  </span>
                </div>
                <div className="flex flex-col">
                  <input
                    name="projectName"
                    id="projectName"
                    type="text"
                    placeholder="Type here..."
                    onChange={(e) =>
                      handleChangeForProjects(
                        e.target.value,
                        index,
                        "projectName",
                        setFieldValue
                      )
                    }
                    value={candidate__experience.projects[index].project_name}
                    className={styles.input}
                  />
                </div>
                <p className="text-sm mt-4">Project Description</p>
                <div className="flex flex-col">
                  <textarea
                    name="projectDescription"
                    id="projectDescription"
                    // onChange={handleChange("projectDescription")}
                    value={
                      candidate__experience.projects[index].project_description
                    }
                    onChange={(e) =>
                      handleChangeForProjects(
                        e.target.value,
                        index,
                        "projectDescription",
                        setFieldValue
                      )
                    }
                    placeholder={`Please describe the working of the company...`}
                    className={`${styles.input} py-2 min-h-100`}
                  ></textarea>
                </div>
              </div>
            ))}
            <div className="max-w-80 mt-1">
              <div
                className={styles.add__filedContainer}
                onClick={() => addOrRemoveField("add")}
              >
                <p className="text-sm font-bold mr-1">
                  {strings.new__candidate__experience.add_project}
                </p>
                <AddCircleOutline size={20} />
              </div>
            </div>

            <div className="flex w-full justify-between mt-6">
              <button className={stylesCss.back_btn_wrap} onClick={back}>
                <LeftArrow className={stylesCss.back_icon} size={20} />
                <Typography className={stylesCss.back_button}>Back</Typography>
                <div className={stylesCss.back_btn_after}></div>
              </button>

              <button className={stylesCss.next_btn_wrap} type="submit">
                <div className={stylesCss.next_btn_before}></div>
                <Typography className={stylesCss.next_button}>
                  Submit
                </Typography>
                <RightArrow className={stylesCss.icon} size={20} />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};
