import React, {useRef} from "react";
import strings from "globals/constants/strings.json";
import {Formik, Form} from "formik";
import * as Yup from "yup";
import {AppColors} from "globals/appcolors";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import styles from "./style.module.scss";
import {LeftArrow, RightArrow} from "components/Icons";

interface Props {
  next: () => void;
  back: () => void;
}

export const IntervieweeDetails: React.FC<Props> = ({next, back}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const {createAssesmentValues, setCreateAssesmentValues} =
    useAppCommonDataProvider();

  const initialValues: {
    firstName: string;
    lastName: string;
    experience: string;
    email: string;
    source: string;
    resume: null | File;
  } = {
    firstName:
      createAssesmentValues?.first_name !== undefined
        ? createAssesmentValues?.first_name
        : "",
    lastName:
      createAssesmentValues?.last_name !== undefined
        ? createAssesmentValues?.last_name
        : "",
    experience: "",
    email:
      createAssesmentValues?.email !== undefined
        ? createAssesmentValues?.email
        : "",
    source:
      createAssesmentValues?.candidate_source !== undefined
        ? createAssesmentValues?.candidate_source
        : "",
    resume: null,
  };

  const validateSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Firstname is required"),

    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Lastname is required"),

    email: Yup.string().email().required("Email is required"),

    source: Yup.string()
      .required("Candiadte source is required")
      .min(2, "Too Short!"),

    resume: Yup.string().required("Please upload a resume").nullable(),
  });

  const uploadResume = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    hiddenFileInput.current?.click();
  };

  const submitForm = async (values: {
    firstName: string;
    lastName: string;
    experience: string;
    email: string;
    source: string;
    resume: null | File;
  }) => {
    //@ts-ignore
    setCreateAssesmentValues?.({
      ...createAssesmentValues,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      candidate_source: values.source,
    });
    next();
  };

  return (
    <div>
      <div>
        <p className="font-bold text-2xl mb-4">Candidate Details</p>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validateSchema}
        onSubmit={submitForm}
        enableReinitialize
      >
        {(formik) => {
          const {
            values,
            handleChange,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            isValid,
            dirty,
            setFieldValue,
          } = formik;
          return (
            // @ts-ignore
            <Form onSubmit={handleSubmit}>
              <div className="pl-2 max-w-full">
                <div>
                  <div className="flex items-center">
                    <div className="flex-1 relative flex flex-col">
                      <p className="text-sm">{strings.first_name}</p>
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        onChange={handleChange("firstName")}
                        onBlur={handleBlur}
                        value={values?.firstName}
                        className="mt-2 bg-white-800 w-4/5 h-10 border border-white-300 border-solid rounded pl-2 text-xs"
                        placeholder={strings.first_name}
                      />
                      {errors.firstName && touched.firstName && (
                        <span className="text-red-500 text-xs mt-2">
                          {errors.firstName}
                        </span>
                      )}
                      {/* {console.log(values, "valuesssssssssssssssss")} */}
                    </div>

                    <div className="flex-1 relative flex flex-col">
                      <p className="text-sm">{strings.last_name}</p>
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        onChange={handleChange("lastName")}
                        onBlur={handleBlur}
                        value={values?.lastName}
                        className="mt-2 bg-white-800 w-4/5 h-10 border border-white-300 border-solid rounded pl-2 text-xs"
                        placeholder={strings.last_name}
                      />
                      {errors.lastName && touched.lastName && (
                        <span className="text-red-500 text-xs mt-2">
                          {errors.lastName}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-8">
                  <p className="text-sm">{strings.email}</p>
                  <div className="relative flex flex-col">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onChange={handleChange("email")}
                      onBlur={handleBlur}
                      value={values?.email}
                      className="mt-2 bg-white-800 w-3/4 h-10 border border-white-300 border-solid rounded pl-2 text-xs"
                      placeholder={strings.email}
                    />
                    {errors.email && touched.email && (
                      <span className="text-red-500 text-xs mt-2">
                        {errors.email}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mt-8 ">
                  <p className="text-sm">
                    {strings.create_assessment.candidate_source}
                  </p>
                  <div className="relative flex flex-col">
                    <input
                      type="text"
                      name="source"
                      id="source"
                      onChange={handleChange("source")}
                      onBlur={handleBlur}
                      value={values?.source}
                      className="mt-2 bg-white-800 w-3/4 h-10 border border-white-300 border-solid rounded pl-2 text-xs"
                      placeholder={strings.create_assessment.candidate_source}
                    />
                    {errors.source && touched.source && (
                      <span className="text-red-500 text-xs mt-2">
                        {errors.source}
                      </span>
                    )}
                  </div>
                </div>
                <div className="mt-8 flex-flex-col">
                  <p className="text-sm">{strings.create_assessment.resume}</p>
                  <div
                    className="mt-2 w-full border border-dotted flex items-center p-4 py-6 rounded"
                    style={{borderColor: AppColors.BLACK_BORDER_LIGHT}}
                  >
                    <div className="flex flex-0.3 items-center justify-center">
                      <button
                        style={{background: AppColors.LIGHT_BG_BLUE}}
                        className="w-1/2 h-10 rounded text-blue-500 font-bold text-sm"
                        onClick={uploadResume}
                      >
                        {strings.upload}
                      </button>
                    </div>
                    <div className="flex flex-0.7 text-black-700 items-center text-sm">
                      {/* {values?.resume
                        ? values?.resume
                        : strings.create_assessment.no_resume} */}
                      <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={(e) => {
                          setFieldValue("resume", e.target.files![0].name);
                          //@ts-ignore
                          setCreateAssesmentValues?.({
                            ...createAssesmentValues,
                            resume: e.target.files![0],
                          });
                        }}
                        style={{display: "none"}}
                      />
                    </div>
                  </div>
                  {errors.resume !== "" && touched.resume === true && (
                    <span className="text-red-500 text-xs mt-2">
                      {errors.resume}
                    </span>
                  )}
                </div>
              </div>

              <div className="flex w-full justify-between mt-6">
                <div className={styles.back_btn_wrap} onClick={() => back()}>
                  <LeftArrow className={styles.back_icon} size={20} />
                  <button className={styles.back_button}>Back</button>
                  <div className={styles.back_btn_after}></div>
                </div>

                <button className={styles.next_btn_wrap} type="submit">
                  <div className={styles.next_btn_before}></div>
                  <button className={styles.next_button}>Next</button>
                  <RightArrow className={styles.icon} size={20} />
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
