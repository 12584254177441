import {
  GOOGLE_ANALYTICS__DEV_TRACKING_ID,
  GOOGLE_ANALYTICS__PROD_TRACKING_ID,
} from "globals/constants/keys";

export enum AppEnv {
  DEV = "DEV",
  STG = "STG",
  PROD = "PROD",
}

const APP_ENV: AppEnv = AppEnv.PROD as AppEnv;

const ConfigurationProd = {
  BASE_URL: "https://api.recruitbae.com/api",
  BASE_NODE_URL: "https://recruitbae.com/api",
  DOMAIN: "https://www.recruitbae.com",
  GOOGLE_ANALYTICS_KEY: GOOGLE_ANALYTICS__PROD_TRACKING_ID,
  SURVEY_FORM: "https://forms.gle/jLrEyn8eQsTxXo297",
  AFFINDA_KEY: "88df4f4a5139c3d1e1339a7ed3e795df46931d13",
};

const ConfigurationStaging = {
  BASE_URL: "https://testrecruitbae.sourcebae.com/api",
  BASE_NODE_URL: "https://testrecruitbae.shethink.in/api",
  DOMAIN: "https://testrecruitbae.shethink.in/",
  GOOGLE_ANALYTICS_KEY: GOOGLE_ANALYTICS__DEV_TRACKING_ID,
  SURVEY_FORM: "https://forms.gle/jLrEyn8eQsTxXo297",
  AFFINDA_KEY: "f5a484797ccb5a3e5e0235685601dd5d4c7757b7",
};

const ConfigurationDev = {
  BASE_URL: "https://testrecruitbae.sourcebae.com/api",
  BASE_NODE_URL: "https://testrecruitbae.shethink.in/api",
  DOMAIN: "http://localhost:3000",
  GOOGLE_ANALYTICS_KEY: GOOGLE_ANALYTICS__DEV_TRACKING_ID,
  SURVEY_FORM: "https://forms.gle/jLrEyn8eQsTxXo297",
  AFFINDA_KEY: "f5a484797ccb5a3e5e0235685601dd5d4c7757b7",
};

const Configuration =
  APP_ENV == AppEnv.PROD
    ? ConfigurationProd
    : APP_ENV === AppEnv.STG
    ? ConfigurationStaging
    : ConfigurationDev;

export const AppConfig = {
  APP_ENV,
  Configuration,
};
