import React from "react";
import styles from "./SignUpStyles.module.scss";
import {HashLink} from "react-router-hash-link";
import {Formik} from "formik";
import * as yup from "yup";
import {SignupCompanyDetailFormValues} from "globals/types/globalTypes";

interface Props {
  handleSecondFormClick: (data: SignupCompanyDetailFormValues) => void;
}

let initialValues: SignupCompanyDetailFormValues = {
  user_designation: "",
  company_name: "",
  website: "",
};

const schema = yup.lazy(() =>
  yup.object().shape({
    user_designation: yup
      .string()
      .min(2, "Mininum 2 characters")
      .max(15, "Maximum 15 characters")
      .required("You must enter a user designation"),
    company_name: yup
      .string()
      .min(2, "Mininum 2 characters")
      .max(30, "Maximum 30 characters")
      .required("Your company name is required"),
    website: yup
      .string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      )
      .required("Your website is required"),
  })
);

export const CompanyDetailForm: React.FC<Props> = ({handleSecondFormClick}) => {
  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(data) => {
          handleSecondFormClick(data);
        }}
        validationSchema={schema}
      >
        {({values, handleSubmit, handleChange, errors, status, touched}) => {
          return (
            <form action="" className={styles.signup__forms}>
              <div className={styles.form__field__wrap}>
                <label htmlFor="">
                  User Designation <span className="text-red-300">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Type Here"
                  name="user_designation"
                  onChange={handleChange}
                />
                {errors.user_designation && touched.user_designation && (
                  <p className={styles.error__text}>
                    {errors?.user_designation}
                  </p>
                )}
              </div>
              <div className={styles.form__field__wrap}>
                <label htmlFor="">
                  Company Name <span className="text-red-300">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Type Here"
                  name="company_name"
                  onChange={handleChange}
                />
                {errors.company_name && touched.company_name && (
                  <p className={styles.error__text}>{errors?.company_name}</p>
                )}
              </div>
              <div className={styles.form__field__wrap}>
                <label htmlFor="">
                  Website <span className="text-red-300">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Type Here"
                  name="website"
                  onChange={handleChange}
                />
                {errors.website && touched.website && (
                  <p className={styles.error__text}>{errors?.website}</p>
                )}
              </div>

              <div className={`${styles.form__field__wrap} md:justify-center`}>
                <button
                  className={styles.signup__form__button_wrap}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  id="company-detail"
                >
                  <HashLink
                    smooth
                    to="/sign-up#create-password"
                    scroll={(el) => el.scrollIntoView({behavior: "smooth"})}
                  >
                    Next
                  </HashLink>
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
