import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import strings from "globals/constants/strings.json";
import {toast} from "react-toastify";
import cookie from "react-cookies";

export const errorHandler = (data: any) => {
  let error = data.response;
  if (error?.status === 401) {
    localStorage.removeItem("AUTH_TOKEN");
    localStorage.removeItem("USER_DETAILS");
    cookie.remove(COOKIESKEYS.AUTH_TOKEN);
    cookie.remove(COOKIESKEYS.USER_DETAILS);
    toast.info(strings.tokenExpireMsg);
    setTimeout(() => {
      window.location.href = `/`;
    }, 700);
  }
  throw error;
};
