import React from "react";
import styles from "./CollegeLandingPageStyles.module.scss";
import strings from "globals/constants/strings.json";
import {Images} from "Images/index";

export const CTA: React.FC = () => {
  return (
    <div
      className={styles.CTA_wrap}
      style={{backgroundImage: `url(${Images.collegeCTA})`}}
    >
      <h1>{strings.College_landing.CTA.title}</h1>
      <button className={styles.college_page_button}>
        {strings.College_landing.CTA.button_text}
      </button>
    </div>
  );
};
