import React from "react";
import styles from "./CollegeLandingPageStyles.module.scss";
import strings from "globals/constants/strings.json";
import {Images} from "Images/index";
import {AiFillTwitterCircle} from "react-icons/ai";

export const Footer: React.FC = () => {
  return (
    <div className={styles.footer_wrap}>
      <div className={styles.footer_logo_wrap}>
        <img src={Images.logo} alt="" />
        <div className="mt-2">
          <AiFillTwitterCircle size={"2rem"} color={"rgba(19, 19, 19, 0.7)"} />
        </div>
      </div>
      <div className={styles.footer_address_wrap}>
        <h1 className={styles.title}>
          {strings.College_landing.footer.address}
        </h1>
        <p className={styles.subtitle}>
          {strings.College_landing.footer.address_subtitle}
        </p>
      </div>
      <div className={styles.footer_contact_wrap}>
        <h1 className={styles.title}>
          {strings.College_landing.footer.contact}
        </h1>
        <p className={styles.subtitle}>
          {strings.College_landing.footer.contact_subtitle}
        </p>
      </div>
    </div>
  );
};
