import React from "react";
import styles from "./CollegeLandingPageStyles.module.scss";
import strings from "globals/constants/strings.json";
import {Images} from "Images/index";

export const WhoWeAre: React.FC = () => {
  return (
    <div className="">
      <div className={styles.whoWeAre_wrap}>
        <div className={styles.title}>
          {strings.College_landing.who_we_are.title}
        </div>
        <div className={styles.whoWeAre_image_wrap}>
          <img src={Images.whoWeAre} alt="" />
        </div>
        <div className={styles.subtitle}>
          {strings.College_landing.who_we_are.sub_title}
        </div>
      </div>
    </div>
  );
};
