import axios from "axios";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {SignJWT} from "jose";
import CryptoJS from "crypto-js";

import {AppConfig} from "appConfig";

const baseURL = AppConfig.Configuration.BASE_URL;
const interceptedInstance = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("AUTH_TOKEN")}`,
  },
});

const candidateInstance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    // Authorization: `Bearer ${localStorage.getItem("candidateToken")}`,
  },
});

candidateInstance.interceptors.request.use((config) => {
  //@ts-ignore
  config.headers["Authorization"] = `Bearer ${localStorage.getItem(
    "candidateToken"
  )}`;
  return config;
});

// interceptedInstance.interceptors.request.use(function (request) {
//   if (!request.url!.includes("login")) {
//     request.headers!["Authorization"] = AppConfig.Configuration.AUTH_TOKEN;
//   }
//   return request;
// });
// interceptedInstance.interceptors.response.use(
//   function (response) {
//     if (response.status === 200) {
//       return response.data;
//     } else if (
//       response.status === 201 ||
//       response.status === 206 ||
//       response.status === 204
//     ) {
//       toast.success(response.data.message || response.statusText, {
//         position: "top-right",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//       });
//       return response.data;
//     } else {
//     }
//   },
//   function (error) {
//     let trueError = "";

//     if (error?.response?.status !== 404) {
//       if (
//         error?.response?.data?.message === "Bearer Token not found" ||
//         error?.response?.data?.message === "Unauthorized" ||
//         error?.response?.data?.error?.includes("CastError") ||
//         error?.response?.data?.error?.includes("MongooseError")
//       ) {
//         if (cookie.load("Authorization")) {
//           alert(error?.response?.data?.message);
//         }
//         cookie.remove("Authorization");
//         window.location.href = "/";
//       } else if (error?.response?.status === 422) {
//         const errors = error?.response?.data?.errors || {};
//         const firstErrorKey = Object.keys(errors)[0];
//         toast.error(errors[firstErrorKey][0]);
//       } else {
//         const errors = error?.response?.data?.error ?? {};
//         const errorName = Object.keys(errors);
//         if (typeof errors === "object" && errorName.length > 0) {
//           trueError = trueError + errors[errorName[0]][0];
//         } else {
//           trueError = error?.response?.data?.message;
//         }
//         toast.error(trueError, {
//           position: "top-right",
//           autoClose: 10000,
//           hideProgressBar: true,
//           pauseOnHover: true,
//           draggable: true,
//         });
//       }
//     } else if (
//       error?.response?.status === 404 &&
//       error?.response?.config?.url?.includes("login")
//     ) {
//       toast.error(error?.response?.data?.message);
//     }
//     return Promise.reject(error);
//   }
// );

// function instanceCreator(apiUrl, method, data, headers, params, config) {
//   return axios.create({
//     baseURL,
//     method: method,
//     url: apiUrl,
//     data: data,
//     headers: headers,
//     params: params,
//     config: config,
//   });
// }

const assembly = axios.create({
  baseURL: "https://api.assemblyai.com/v2",
  headers: {
    authorization: "01a903b2f5b849a49826c12e88602bfb",
  },
});

export {
  interceptedInstance as default,
  assembly,
  candidateInstance,
  // instanceCreator
};

export const newInstance = axios.create({
  baseURL: "https://utility.recruitbae.com/",
  params: {},
  data: {},
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 60000,
});

newInstance.interceptors.request.use((config) => {
  let token = localStorage.getItem("token");
  if (token !== null) {
    token = `Bearer ${token}`;
    config.headers!.Authorization = `${token}`;
  }
  return config;
});

const createEncryptedHash = (text: string, secret: string): string => {
  const encrypted = CryptoJS.AES.encrypt(text, secret).toString();
  return encrypted;
};

const generateToken = async (): Promise<string> => {
  const mySecretKey = "n4HdFq0dAZF04eZnosKI";
  const plaintext = `${Date.now()}/Sourcebae.com$2017$987789!#/${Date.now()}`;
  const apiKey = createEncryptedHash(plaintext, mySecretKey ?? "");
  const encoded = {
    key: apiKey,
  };
  const token = await new SignJWT(encoded)
    .setProtectedHeader({alg: "HS256"})
    .setExpirationTime("2 minute")
    .sign(new TextEncoder().encode(mySecretKey));
  return token;
};

export const sourcebaeInstance = axios.create({
  // baseURL: AppConfig.Configuration.sourcebaeUrl,
  baseURL: "https://stagingsourcebae.shethink.in/api",
  params: {},
  data: {},
  headers: {
    Authorization: localStorage.getItem("token") ?? "",
  },
});
sourcebaeInstance.interceptors.request.use(async (config) => {
  config.headers!.apiKey = await generateToken();
  return config;
});

export const emotionInstance = axios.create({
  // baseURL: AppConfig.Configuration.emotionUrl,
  baseURL: "https://utility.recruitbae.com/",
  params: {},
  data: {},
  headers: {
    // Authorization: token
    "Content-Type": "application/json",
  },
});
