import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Link} from "react-router-dom";
import cookie from "react-cookies";
import {Popover} from "@mui/material";
import styles from "./AppNav.module.css";

import {routes, specialRoutes} from "components/NavBars/AppNav/routes";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {Close, Logout, User, Users} from "components/Icons";

import logo from "globals/svgs/logo/SourceBaeLogo.svg";
import {COOKIESKEYS} from "globals/constants/cookiesKeys";
import {AppColors} from "globals/appcolors";
import {RouteLinks} from "globals/constants/routeLinks";
import {postGoogleAnalyticsEvent} from "globals/helpers";

const AppNav: React.FC = () => {
  const location = useLocation();
  const {
    setAssessments_timestamp,
    setAssessments,
    setLoading,
    setAssessments_template_timestamp,
    setAssessmentTemplates,
    setquestionData,
    setAssessment_details,
    setActive_navbar_index,
    setInterview_details,
    setnewInterviewsPageCount,
    setoldInterviewsPageCount,
    setAllQuestions,
    setTotalNumberOfQuestions,
    setScrollOffset,
  } = useAppCommonDataProvider();
  const navigate = useNavigate();
  const eventTracker = postGoogleAnalyticsEvent("User");

  const userDetails = JSON.parse(localStorage.getItem("USER_DETAILS")!);
  const {user} = userDetails || {};

  const PopoverCTAButtons = [
    {
      title: "Profile",
      icon: <User size={16} color={AppColors.PRIMARYCOLOR} />,
      onClick: () => {
        setAnchorEl(null);
        navigate(`${RouteLinks.Profile}`);
      },
    },
    {
      title: "Logout",
      icon: <Logout size={17} color={AppColors.PRIMARYCOLOR} />,
      onClick: () => onLogout(),
    },
  ];

  let lochash = `/${location.pathname.replace("/", " ").trim().split("/")[0]}`;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const Role: string = localStorage.getItem("Role")!;

  const onLogout = () => {
    setAssessments_timestamp?.(null);
    setAssessments?.(null);
    setLoading?.(false);
    setAssessments_template_timestamp?.(null);
    setAssessmentTemplates?.(null);
    setquestionData?.(null);
    setAssessment_details?.(null);
    setActive_navbar_index?.(0);
    setInterview_details?.(null);
    setnewInterviewsPageCount?.({count: 0, hasReachedLast: false});
    setoldInterviewsPageCount?.({count: 0, hasReachedLast: false});
    setAllQuestions?.(null);
    setTotalNumberOfQuestions?.(null);
    setScrollOffset?.(null);
    localStorage.clear();
    cookie.remove(COOKIESKEYS.AUTH_TOKEN);
    cookie.remove(COOKIESKEYS.USER_DETAILS);
    cookie.remove(COOKIESKEYS.IS_VERIFIED);
    cookie.remove(COOKIESKEYS.PASSWORD);
    cookie.remove(COOKIESKEYS.INTERVIEW_DETAILS);
    cookie.remove(COOKIESKEYS.RESPONSES);
    cookie.remove(COOKIESKEYS.REMAINING_TIME);
    cookie.remove(COOKIESKEYS.DURATION);
    cookie.remove(COOKIESKEYS.UNIQUE_RESPONSES);
    cookie.remove(COOKIESKEYS.JWT);
    eventTracker("User logging out", "User logged out");

    navigate(`/${RouteLinks.Login}`);
  };

  const handleClick = (event: any) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      {!(
        specialRoutes.includes(location.pathname) ||
        specialRoutes.includes(lochash)
      ) && (
        <div className="bg-primary w-screen flex items-center py-3 sticky">
          <div className="flex justify-center px-2" style={{width: "15%"}}>
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div
            className="flex items-center justify-center"
            style={{width: "70%"}}
          >
            {routes.map((route) => {
              return (
                <Link
                  className={`${styles.link} ${
                    location.pathname.includes(route.link) && styles.active
                  }`}
                  to={route.link}
                  key={route.name}
                >
                  <div className="w-full flex items-center py-2 justify-start">
                    <div className="flex mr-2">
                      <img
                        src={route.logo}
                        alt="logo"
                        style={{
                          width: 25,
                          height: 25,
                          opacity: location.pathname.includes(route.link)
                            ? 1
                            : 0.7,
                        }}
                      />
                    </div>
                    <div
                      className={`font-mulish font-semibold ${
                        !location.pathname.includes(route.link) && "opacity-70"
                      }`}
                      style={{fontSize: 12}}
                    >
                      {route.name}
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
          <div style={{width: "10%"}}>
            <div className={`flex items-center justify-end`}>
              <div>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <div
                    className="bg-white-900 flex justify-center rounded-xl"
                    style={{width: 300}}
                  >
                    <div className="w-80 py-4 pb-8">
                      <div
                        className="flex justify-end cursor-pointer"
                        onClick={handleClose}
                      >
                        <Close size={25} color={AppColors.PRIMARYCOLOR} />
                      </div>
                      <div className="mt-2">
                        <h2 className="text-3xl text-green-200 font-bold">
                          Hi {user && user.first_name}
                        </h2>
                        <p className="mt-2 text-blue-300 text-sm font-semibold">
                          Thank you for being a part of RecruitBae. We thrive to
                          keep serving you better. Please find additional
                          details related to your account below.
                        </p>
                        {Role == "Super Admin" ? (
                          <button
                            className="mt-4 border-b w-80 flex justify-start items-center pb-2"
                            onClick={() => {
                              navigate(`/${RouteLinks.RoleManagement}`);
                              setAnchorEl(null);
                            }}
                            style={{
                              borderBottomColor: AppColors.BLACK_BORDER_LIGHT,
                            }}
                          >
                            <Users size={16} color={AppColors.PRIMARYCOLOR} />
                            <span className="ml-2 font-semibold text-green-200">
                              User Management
                            </span>
                          </button>
                        ) : null}
                        {PopoverCTAButtons.map((button) => (
                          <button
                            key={button.title}
                            className="mt-4 border-b w-80 flex justify-start items-center pb-2"
                            style={{
                              borderBottomColor: AppColors.BLACK_BORDER_LIGHT,
                            }}
                            onClick={() => button.onClick()}
                          >
                            {button.icon}
                            <span className="ml-2 font-semibold text-green-200">
                              {button.title}
                            </span>
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </Popover>

                <button
                  onClick={handleClick}
                  className="w-11 h-11 bg-white-900 cursor-pointer flex items-center justify-center font-extrabold"
                  style={{borderRadius: "50%"}}
                >
                  {user && user.first_name.slice(0, 1).toUpperCase()}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppNav;
