import React from "react";
import Modal from "react-responsive-modal";
import {Button, Typography} from "@mui/material";

import {Row} from "components/Row";

import {halfModalContainer} from "globals/constants/styles";
import {CustomModalBaseProps} from "globals/types/globalTypes";
import strings from "globals/constants/strings.json";
import {Bounce} from "react-activity";

interface IProps extends CustomModalBaseProps {
  loading: boolean;
  onClickProceed: () => void;
}

export const RoundTwoInstructions: React.FC<IProps> = ({
  open,
  loading,
  onClose,
  onClickProceed,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      styles={{
        modal: {
          ...halfModalContainer,
          minWidth: "65vw",
          maxWidth: "65vw",
          maxHeight: "80vh",
        },
      }}
      center
      showCloseIcon={false}
    >
      <Typography variant="h2" sx={{fontWeight: "semibold"}}>
        {`${strings.hi}!`}
      </Typography>

      {[
        strings.roundTwoInstructions.welcome,
        strings.roundTwoInstructions.psnote,
      ].map((text, index) => (
        <Typography
          variant="subtitle1"
          sx={{marginTop: 1, fontSize: 17}}
          key={index.toString()}
        >
          {text}
        </Typography>
      ))}

      <section className="ml-2 mt-2">
        <ul className="bg-[#eff4ff] py-3 px-6 rounded-xl">
          {strings.roundTwoInstructions.pointers.map((instruction, index) => (
            <li
              key={index.toString()}
              style={{
                fontSize: 16,
                fontWeight: "600",
                marginBottom: 5,
              }}
            >
              <Typography variant="body1" sx={{fontWeight: "600"}}>
                <span className="mr-2">{`${index + 1}.`}</span>
                {instruction}
              </Typography>
            </li>
          ))}
        </ul>
      </section>

      <Row classNames="mt-3 justify-end">
        <Button
          onClick={onClickProceed}
          sx={{
            width: 150,
            height: 35,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading === true ? (
            <Bounce />
          ) : (
            <Typography
              variant="button"
              sx={{
                fontWeight: "bold",
              }}
            >
              Understood
            </Typography>
          )}
        </Button>
      </Row>
    </Modal>
  );
};
