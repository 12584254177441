export enum RouteLinks {
  Dashboard = "dashboard",
  Login = "login",
  Reset_Password = "reset-password",
  Password_Change = "change-password",
  Test = "test",
  Assessment = "assessment",
  Assessment_Page = "assessments",
  Assessment_Test = "assessment-test",
  Assessment_Details = "showassessmentdetail",
  Candidate = "candidate",
  AllQuestion = "all-question",
  Interview = "interview",
  Interview__Questions = "interview/questions",
  Interview_Finish = "interview/finish",
  Profile = "profile",
  RoleManagement = "role-management",
  SignUp = "sign-up",
  Pricing = "pricing",
  Candidate_Login = "candidate/login",
  ReportCard = "download_reportcard",
  AllInterviews = "all-interviews",
  SeeAnswers = "see-answers",
  Interview_Round_2 = "interview/round/2",
}

export const QuestionLinks = {
  Create_Question: `${RouteLinks.AllQuestion}/create-question`,
  View_Your_Questions: `${RouteLinks.AllQuestion}/view-your-questions`,
  Csv_Questions: `${RouteLinks.AllQuestion}/csv-questions`,
};

export const CandidateLinks = {
  Candidate_Detail: `${RouteLinks.Candidate}/candidate-detail`,
  See_Ansers: `${RouteLinks.SeeAnswers}`,
};

export const DashboardLinks = {};

export const AssesmentLinks = {
  Create_Assessment: `${RouteLinks.Assessment_Page}/create`,
  Expired_Assessment: `${RouteLinks.Assessment_Page}/expired`,
  View_Assessment_Questions: `${RouteLinks.Assessment_Page}/assessment-details`,
};

export const AssesmentTemplateLinks = {
  Create_Assessment_Test: `${RouteLinks.Assessment_Test}/create`,
  Reuse_Assessment: `${RouteLinks.Assessment_Test}/reuse`,
  View_Question_Set: `${RouteLinks.Assessment_Test}/view_question_set`,
};

export const AllInterviewsLinks = {
  AllInterviewpage: `${RouteLinks.AllInterviews}`,
};
