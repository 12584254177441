// @ts-nocheck
import React, {useState, useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import moment from "moment";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  PDFViewer,
  Link,
} from "@react-pdf/renderer";
import {reportCardStyles} from "./reportCardStyles";

import {AppColors} from "globals/appcolors";
import {capitalizeWord} from "globals/helpers";
import {CandidateDetails, ResumeData} from "globals/types/APIResponses";
import {
  CandidateResumeEducation,
  CandidateResumeExperience,
  CandidateResumeSkills,
} from "globals/types/globalTypes";
import {assembly, newInstance} from "globals/axiosConstants";
import {ReportCardDummyMiscData} from "globals/constants/InterviewConstants";

import {useFetchAssessmentDetails} from "hooks/interview/useFetchAssessmentDetails";
import {useResumeData} from "hooks/candidate/useResumeData";
import {useFetchSecondRoundResponses} from "hooks/interview/useFetchSecondRoundResponses";
import {MdAssignmentReturned} from "react-icons/md";

const sampleResponse = {
  accuracy: 0.0,
  communication: 0.0,
  confidence: 12.5,
  resume_content: {
    experience_and_projects: [
      {
        description:
          "N23 is a web based application for automation of manual work process using machine learning models. In this project label-studio is integrated for labelling the data",
        duration: "April 2022- August 2023",
        team_size: "6 members",
        technologies:
          "Python, Django, Microservice, React, RDS,AWS ECS, S3, Lambda, Label-studio, PostgreSQL, Docker, Okta, SAML",
        title: "Content Elbert",
      },
      {
        description:
          "PGI project is used for integrating phrases and gitlab. Gitlab is a version controlling system and Phrase is a tool, which translates the file from source language to given(target languages)",
        duration: "April 2021- March 2022",
        team_size: "5 members",
        technologies:
          "Python, Multithreading, React, Django, Microservice, Postgres, JavaScript, HTML5, CSS3, Bootstrap",
        title: "Phrase-Gitlab Integration",
      },
      {
        description:
          "In an existing Project adding some extra functionality like Dynamic form creation, User Permission model, Data Change Capture, Form Change capture, Athena migration, Data Tagging, Report generation, Optimization, Validation , Adding Asynchronous Celery task",
        duration: "March 2020- April 2021",
        team_size: "5 members",
        technologies:
          "Python, Django Rest Framework, S3, React, AWS EC2,RDS,AWS Athena",
        title: "Real Estate Leonardo",
      },
      {
        description:
          "Revealera is a product that analyzes the hiring trends for companies and usage trends for SaaS products.This app is to provide analysis on jobs/hiring trends of all the best companies in the world for that it scrapes the content of hiring trends and putting into the DB and exposing to rest API",
        duration: "March 2019- Feb 2020",
        team_size: "4 members",
        technologies: "Python3, Django, Mysql, AWS Lambda, Pandas",
        title: "Revealera",
      },
    ],
    introduction:
      "I have 6 years of experience in Python development with knowledge of popular web frameworks such as Django, Django RestFramework, RESTful APIs,RDS, Falsk, FastAPI, Airflow,SQSMicroservice, and React. Design and implement database schemas and queries to support backend functionality. Designed and developed data pipelines using various technologies such as Python, AWS, and Docker. Familiarity with cloud technologies such as AWS Lambda, EC2, AWS S3, and Cloud. Worked on Label Studio integration with Machine learning dataset Knowledge of Databases like Postgres, MySQL, AWS Redshift Knowledge of version control tool GIT. Highly motivated self-learner with good problem-solving skills and committed to learning new technologies. Ability to handle (multiple) tasks and can work in person as well as part of a team SQL for database management and query optimization",
    expertise: {
      cloud_services: [
        "AWS Lambda",
        "EC2",
        "SQS",
        "Airflow S3",
        "AWS ECS",
        "Cloud",
        "Step Functions",
        "RDS",
      ],
      containerization: ["Docker"],
      databases: ["MySQL", "PostgreSQL", "Oracle", "MongoDB", "AWS Redshift"],
      frameworks: [
        "Django",
        "FastApi",
        "Flask",
        "ReactJS",
        "Django Rest Framework",
      ],
      languages: ["Python", "JavaScript"],
      source_code_control: ["GitHub", "Bitbucket", "Gitlab"],
    },
    name: "RAJAT",
    title: "Python Developer",
  },
};

interface SentimentAverage {
  POSITIVE: number;
  NEGATIVE: number;
  NEUTRAL: number;
}
interface flavour {
  include_intro: boolean;
  include_round_one: boolean;
  include_round_two: boolean;
}

export const ReportCard: React.FC = () => {
  const [searchParams] = useSearchParams();

  const [assessmentData, setAssessmentData] = useState({});
  const [resumeData, setResumeData] = useState({});
  const [sentimentData, setSetsentimentData] = useState({});
  const [candidateName, setCandidateName] = useState<string>("");
  const [candidateEmail, setCandidateEmail] = useState<string>("");
  const [allSkills, setAllSkills] = useState<string[]>([]);

  const assesmentId = searchParams.get("assessment");
  const aiId = searchParams.get("aiId");

  const {refetch: getAssessmentDetails} =
    useFetchAssessmentDetails(assesmentId);

  const fetchAssessmentDetails = () => {
    getAssessmentDetails().then(async (res) => {
      if (res.isError === false) {
        if (res.data !== undefined) {
          const {resume_url, main_question_id} = res.data.data.assessment;

          const {
            user: {first_name, last_name, email},
          } = res.data.data.assessment.candidate;

          const evaluationbody = new FormData(),
            parseResumeBody = new FormData();

          evaluationbody.append("main_question_id", main_question_id);
          parseResumeBody.append("s3_url", resume_url);

          const assessmentData = await newInstance.get(
            `/get-assessment/${aiId}`
          );

          setAssessmentData(assessmentData.data);

          const evaluationResponse = await newInstance.post(
            `/calculate-evaluation`,
            evaluationbody
          );

          const parsedResumeResponse = await newInstance.post(
            "/resume-parse",
            parseResumeBody
          );

          setResumeData(parsedResumeResponse.data);
          setSetsentimentData(evaluationResponse.data);

          setCandidateName(
            `${capitalizeWord(first_name)} ${capitalizeWord(last_name)}`
          );
          setCandidateEmail(email);

          const {
            resume_content: {skills},
          } = parsedResumeResponse.data;

          const temp = [];

          if (Object.values(skills).length > 0) {
            Object.values(skills).forEach((skillsArr) => {
              skillsArr.forEach((skill) => temp.push(skill));
            });
            setAllSkills(temp);
          }
        }
      }
    });
  };

  const {resume_content: {experience = [], introduction, title = ""} = {}} =
    resumeData;

  const {main_question: {questions = []} = {}} = assessmentData;

  const {accuracy} = sentimentData;

  useEffect(() => {
    fetchAssessmentDetails();
  }, []);

  return Object.keys(sentimentData).length > 0 &&
    Object.keys(resumeData).length > 0 &&
    Object.keys(assessmentData).length > 0 ? (
    <PDFViewer showToolbar={true} style={{height: "100vh"}}>
      <Document>
        <Page size="A4" style={reportCardStyles.page}>
          <View
            style={[reportCardStyles.row, {justifyContent: "space-between"}]}
          >
            <View>
              <Text style={reportCardStyles.name}>{candidateName}</Text>
              <Text style={reportCardStyles.title}>{title}</Text>
            </View>

            <View>
              <Text
                style={reportCardStyles.lightText}
              >{`Email - ${candidateEmail}`}</Text>
            </View>
          </View>

          <View style={reportCardStyles.section}>
            <Text style={reportCardStyles.titleText}>Introduction</Text>
            <Text style={reportCardStyles.descriptiveText}>{introduction}</Text>
          </View>

          <View style={reportCardStyles.section}>
            <Text style={reportCardStyles.titleText}>
              Experience & Projects
            </Text>
            {experience &&
              Array.isArray(experience) &&
              experience.map(
                ({company, duration, position, responsibilities}, index) => {
                  return (
                    <View style={{marginTop: 10, marginLeft: 5}}>
                      <Text
                        style={[
                          reportCardStyles.descriptiveText,
                          {fontWeight: "bold"},
                        ]}
                      >
                        {`${index + 1}. ${company} (${duration})`}
                      </Text>
                      <Text
                        style={[
                          reportCardStyles.descriptiveText,
                          {paddingLeft: 15},
                        ]}
                      >
                        {position}
                      </Text>
                      {responsibilities.map(
                        (responsibility, responsibilityIndex) => (
                          <Text
                            style={[
                              reportCardStyles.descriptiveText,
                              {paddingLeft: 25},
                            ]}
                          >{`${
                            responsibilityIndex + 1
                          }. ${responsibility}`}</Text>
                        )
                      )}
                    </View>
                  );
                }
              )}
          </View>

          <View
            style={{
              backgroundColor: "#cecece",
              height: 1,
              width: "100%",
              marginTop: 30,
            }}
          ></View>

          <View style={reportCardStyles.section}>
            <Text style={reportCardStyles.titleText}>Expertise</Text>
            {allSkills.map((e, index) => (
              <View style={{marginLeft: 15, marginTop: 5}} key={e}>
                <Text style={reportCardStyles.descriptiveText} key={e}>
                  {`${index + 1}. ${e}`}
                </Text>
              </View>
            ))}
          </View>

          <View
            style={{
              backgroundColor: "#cecece",
              height: 1,
              width: "100%",
              marginTop: 30,
            }}
          ></View>

          <View>
            {questions.map(({questionAnswer, s3_url}, index) => (
              <View style={{marginTop: 15, flexDirection: "row"}}>
                <View
                  style={{
                    minWidth: "60%",
                    maxWidth: "60%",
                  }}
                >
                  <Text style={reportCardStyles.titleText}>{`Question - ${
                    index + 1
                  }`}</Text>
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 11,
                      marginTop: 10,
                    }}
                  >
                    {`Answer -`}
                    <Text style={reportCardStyles.descriptiveText}>{` ${
                      questionAnswer === "" ? "N.A." : questionAnswer
                    }`}</Text>
                  </Text>
                </View>

                {s3_url.length > 0 && (
                  <View style={{width: "40%", alignItems: "flex-end"}}>
                    <Image
                      source={s3_url[0]}
                      style={{width: "80%", borderRadius: 5}}
                    />
                  </View>
                )}
              </View>
            ))}
          </View>

          <View
            style={{
              backgroundColor: "#cecece",
              height: 1,
              width: "100%",
              marginTop: 30,
            }}
          ></View>

          <View style={{marginTop: 30}}>
            <Text style={reportCardStyles.titleText}>OVERALL RATING</Text>
            <View style={{flexDirection: "row", marginTop: 20}}>
              <View style={{minWidth: "30%"}}>
                <View>
                  <Text style={reportCardStyles.heading}>Performance</Text>
                  <Text
                    style={{
                      color: AppColors.PRIMARYCOLOR,
                      fontSize: 40,
                      marginTop: 4,
                      fontWeight: "bold",
                    }}
                  >{`${accuracy}%`}</Text>
                </View>
              </View>

              <View style={{minWidth: "70%"}}>
                <Text style={reportCardStyles.heading}>Skill Scores</Text>
                {Object.keys(sentimentData).map((sentiment) => {
                  return (
                    <View style={{marginTop: 15}}>
                      <Text>{capitalizeWord(sentiment)}</Text>
                      <View
                        style={{
                          position: "relative",
                          width: "80%",
                          height: 6,
                          backgroundColor: "white",
                          borderRadius: 4,
                          marginTop: 5,
                        }}
                      >
                        <View
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            width: `${sentimentData[sentiment]}%`,
                            backgroundColor: AppColors.PRIMARYCOLOR,
                            borderRadius: "100%",
                          }}
                        ></View>
                        <View
                          style={{position: "absolute", left: "102%", top: -5}}
                        >
                          <Text
                            style={reportCardStyles.heading}
                          >{`${sentimentData[sentiment]}%`}</Text>
                        </View>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  ) : null;
};
