import React, {
  Dispatch,
  SetStateAction,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  LinearProgress,
  linearProgressClasses,
  styled,
} from "@mui/material";
import {FullScreen, FullScreenHandle} from "react-full-screen";
import styles from "./SecondRoundInterview.module.scss";
import interviewCommonStyles from "./Interview.module.scss";

import {Row} from "components/Row";
import {CountDownTimer} from "components/Timer";
import {Webcam} from "components/Webcam";

import {AppColors} from "globals/appcolors";
import {SecondRoundQuestion} from "globals/types/globalTypes";
import {
  SecondRoundPerQuestionTime,
  SecondRoundScreenshotsPerQuestion,
  corresponding,
  facialExpressions,
} from "globals/constants/InterviewConstants";
import {
  CameraControls,
  ContactShadows,
  Environment,
  useAnimations,
  useGLTF,
} from "@react-three/drei";
import {Leva, button, useControls} from "leva";
import {Canvas, useFrame} from "@react-three/fiber";
import * as THREE from "three";
import {useChat} from "components/ChatProvider";
import vmsg from "vmsg";
import {uploadFileToS3} from "globals/helpers";
import {useAppCommonDataProvider} from "components/AppCommonDataProvider";
import {emotionInstance, newInstance} from "globals/axiosConstants";
import {useUpdateSecondRoundResponse} from "hooks/interview/useUpdateSecondRoundResponse";
import {useSearchParams} from "react-router-dom";
interface IProps {
  videoConstraints: MediaTrackConstraints;
  fullScreenHandle: FullScreenHandle;
  showTimer: boolean | undefined;
  questions: SecondRoundQuestion[];
  isTestFinishing: boolean | undefined;
  handleFullScreenChange: (state: boolean, handle: any) => void;
  onPressEnd: () => void;
  stopRecording: () => void;
}

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#F5F7FF",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#00D121",
  },
}));

let setupMode = false;
let recorder: any;

const Model = ({
  currentQuestion,
  saveRecording,
  setProcessingAudio,
}: {
  currentQuestion: SecondRoundQuestion;
  saveRecording: boolean;
  setProcessingAudio: Dispatch<SetStateAction<boolean>>;
}) => {
  const {nodes, materials, scene} = useGLTF(
    "/models/64f1a714fe61576b46f27ca2.glb"
  );
  const {animations} = useGLTF("/models/animations.glb");

  const {lipAudioPlayed, onMessagePlayed, setLipAudioPlayed, setAnswer} =
    useChat();
  const {interview_details} = useAppCommonDataProvider();

  const [animation, setAnimation] = useState(() => {
    if (animations && animations.length) {
      return animations.find((a) => a.name === "Idle")
        ? "Idle"
        : animations[0].name; // Check if Idle animation exists otherwise use first animation
    }
    return "Idle";
  });
  const [facialExpression, setFacialExpression] = useState("");
  const [lipsync, setLipsync] = useState<any>({});
  const [audio, setAudio] = useState<HTMLAudioElement>();
  const [blink, setBlink] = useState<boolean>(false);
  const [winkLeft, setWinkLeft] = useState<boolean>(false);
  const [winkRight, setWinkRight] = useState<boolean>(false);

  const group = useRef<THREE.Group<THREE.Object3DEventMap>>(null);
  const {actions, mixer} = useAnimations(animations, group);

  const startRecording = async () => {
    // @ts-expect-error Recorder exists on the vmsg object but does not show up in IntelliSense for some reason
    recorder = new vmsg.Recorder({
      wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm",
    });
    await recorder.initAudio();
    await recorder.initWorker();
    recorder.startRecording();
  };

  const stopRecording = async () => {
    const blob = await recorder.stopRecording();

    const audioFileName = "audio.mp3";
    const audioFileType = "audio/mp3";
    const audioLink = await uploadFileToS3(
      blob,
      audioFileName,
      audioFileType,
      "mp3"
    );

    const formData = new FormData();
    formData.append("s3_url", audioLink);
    formData.append(
      "documentId",
      interview_details?.data.assessment.main_question_id!
    );
    formData.append("questionId", currentQuestion.ai_question_id);

    newInstance
      .post("/transcribe-audio", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setAnswer?.(res.data.answer);
      })
      .finally(() => {
        setProcessingAudio(false);
      });
  };

  useEffect(() => {
    if (!currentQuestion) {
      setAnimation("Idle");
      return;
    }

    setFacialExpression(currentQuestion.facialExpression);
    setLipsync(JSON.parse(currentQuestion.lipsync_data));

    const newAudio = new Audio(currentQuestion.audio_url);

    newAudio.onended = onMessagePlayed;
    newAudio.play();
    setAudio(newAudio);
  }, [currentQuestion]);

  useEffect(() => {
    actions?.[animation]
      ?.reset()
      // @ts-ignore
      ?.fadeIn(mixer.stats.actions.inUse === 0 ? 0 : 0.5)
      .play();

    // return () => actions?.[animation]?.fadeOut(0.5);
  }, [animation]);

  useEffect(() => {
    let blinkTimeout: NodeJS.Timeout;
    const nextBlink = () => {
      blinkTimeout = setTimeout(() => {
        setBlink(true);
        setTimeout(() => {
          setBlink(false);
          nextBlink();
        }, 200);
      }, THREE.MathUtils.randInt(1000, 5000));
    };
    nextBlink();
    return () => clearTimeout(blinkTimeout);
  }, []);

  useEffect(() => {
    if (lipAudioPlayed) startRecording();
  }, [lipAudioPlayed]);

  useEffect(() => {
    if (saveRecording) {
      stopRecording();
      setLipAudioPlayed?.(false);
    }
  }, [saveRecording]);

  const lerpMorphTarget = (target: any, value: any, speed = 0.1) => {
    scene.traverse((child) => {
      // @ts-ignore
      if (child.isSkinnedMesh && child.morphTargetDictionary) {
        // @ts-ignore
        const index = child.morphTargetDictionary[target];
        if (
          index === undefined ||
          // @ts-ignore
          child.morphTargetInfluences[index] === undefined
        ) {
          return;
        }
        // @ts-ignore
        child.morphTargetInfluences[index] = THREE.MathUtils.lerp(
          // @ts-ignore
          child.morphTargetInfluences[index],
          value,
          speed
        );

        if (!setupMode) {
          try {
            set({
              [target]: value,
            });
          } catch (e) {}
        }
      }
    });
  };

  useFrame(() => {
    !setupMode &&
      // @ts-ignore
      Object.keys(nodes.EyeLeft.morphTargetDictionary).forEach((key) => {
        // @ts-ignore
        const mapping = facialExpressions[facialExpression];
        if (key === "eyeBlinkLeft" || key === "eyeBlinkRight") {
          return; // eyes wink/blink are handled separately
        }
        if (mapping && mapping[key]) {
          lerpMorphTarget(key, mapping[key], 0.1);
        } else {
          lerpMorphTarget(key, 0, 0.1);
        }
      });

    lerpMorphTarget("eyeBlinkLeft", blink || winkLeft ? 1 : 0, 0.5);
    lerpMorphTarget("eyeBlinkRight", blink || winkRight ? 1 : 0, 0.5);

    // LIPSYNC
    if (setupMode) {
      return;
    }

    const appliedMorphTargets: any[] = [];
    if (currentQuestion && lipsync) {
      const currentAudioTime = audio?.currentTime;

      for (let i = 0; i < lipsync.mouthCues.length; i++) {
        const mouthCue = lipsync.mouthCues[i];

        if (currentAudioTime) {
          if (
            currentAudioTime >= mouthCue.start &&
            currentAudioTime <= mouthCue.end
          ) {
            // @ts-ignore
            appliedMorphTargets.push(corresponding[mouthCue.value]);
            // @ts-ignore
            lerpMorphTarget(corresponding[mouthCue.value], 1, 0.2);
            break;
          }
        }
      }
    }

    Object.values(corresponding).forEach((value) => {
      if (appliedMorphTargets.includes(value)) {
        return;
      }
      lerpMorphTarget(value, 0, 0.1);
    });
  });

  useControls("FacialExpressions", {
    chat: button(() => console.log("click of button")),
    winkLeft: button(() => {
      setWinkLeft(true);
      setTimeout(() => setWinkLeft(false), 300);
    }),
    winkRight: button(() => {
      setWinkRight(true);
      setTimeout(() => setWinkRight(false), 300);
    }),
    animation: {
      value: animation,
      options: animations.map((a) => a.name),
      onChange: (value) => setAnimation(value),
    },
    facialExpression: {
      options: Object.keys(facialExpressions),
      onChange: (value) => setFacialExpression(value),
    },
    enableSetupMode: button(() => {
      setupMode = true;
    }),
    disableSetupMode: button(() => {
      setupMode = false;
    }),
    logMorphTargetValues: button(() => {
      const emotionValues = {};
      // @ts-ignore
      Object.keys(nodes.EyeLeft.morphTargetDictionary).forEach((key) => {
        if (key === "eyeBlinkLeft" || key === "eyeBlinkRight") {
          return; // eyes wink/blink are handled separately
        }
        const value =
          // @ts-ignore
          nodes.EyeLeft.morphTargetInfluences[
            // @ts-ignore
            nodes.EyeLeft.morphTargetDictionary[key]
          ];
        if (value > 0.01) {
          // @ts-ignore
          emotionValues[key] = value;
        }
      });
    }),
  });

  const [, set] = useControls("MorphTarget", () =>
    Object.assign(
      {},
      // @ts-ignore
      ...Object.keys(nodes.EyeLeft.morphTargetDictionary).map((key) => {
        return {
          [key]: {
            label: key,
            value: 0,
            // @ts-ignore
            min: nodes.EyeLeft.morphTargetInfluences[
              // @ts-ignore
              nodes.EyeLeft.morphTargetDictionary[key]
            ],
            max: 1,
            onChange: (val: any) => {
              if (setupMode) {
                lerpMorphTarget(key, val, 1);
              }
            },
          },
        };
      })
    )
  );

  return (
    <group ref={group} dispose={null}>
      <primitive object={nodes.Hips} />
      <skinnedMesh
        name="Wolf3D_Body"
        // @ts-ignore
        geometry={nodes.Wolf3D_Body.geometry}
        material={materials.Wolf3D_Body}
        // @ts-ignore
        skeleton={nodes.Wolf3D_Body.skeleton}
      />
      <skinnedMesh
        name="Wolf3D_Outfit_Bottom"
        // @ts-ignore
        geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
        material={materials.Wolf3D_Outfit_Bottom}
        // @ts-ignore
        skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
      />
      <skinnedMesh
        name="Wolf3D_Outfit_Footwear"
        // @ts-ignore
        geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
        material={materials.Wolf3D_Outfit_Footwear}
        // @ts-ignore
        skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
      />
      <skinnedMesh
        name="Wolf3D_Outfit_Top"
        // @ts-ignore
        geometry={nodes.Wolf3D_Outfit_Top.geometry}
        material={materials.Wolf3D_Outfit_Top}
        // @ts-ignore
        skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
      />
      <skinnedMesh
        name="Wolf3D_Hair"
        // @ts-ignore
        geometry={nodes.Wolf3D_Hair.geometry}
        material={materials.Wolf3D_Hair}
        // @ts-ignore
        skeleton={nodes.Wolf3D_Hair.skeleton}
      />
      <skinnedMesh
        name="EyeLeft"
        // @ts-ignore
        geometry={nodes.EyeLeft.geometry}
        material={materials.Wolf3D_Eye}
        // @ts-ignore
        skeleton={nodes.EyeLeft.skeleton}
        // @ts-ignore
        morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
        // @ts-ignore
        morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
      />
      <skinnedMesh
        name="EyeRight"
        // @ts-ignore
        geometry={nodes.EyeRight.geometry}
        material={materials.Wolf3D_Eye}
        // @ts-ignore
        skeleton={nodes.EyeRight.skeleton}
        // @ts-ignore
        morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
        // @ts-ignore
        morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
      />
      <skinnedMesh
        name="Wolf3D_Head"
        // @ts-ignore
        geometry={nodes.Wolf3D_Head.geometry}
        material={materials.Wolf3D_Skin}
        // @ts-ignore
        skeleton={nodes.Wolf3D_Head.skeleton}
        // @ts-ignore
        morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
        // @ts-ignore
        morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
      />
      <skinnedMesh
        name="Wolf3D_Teeth"
        // @ts-ignore
        geometry={nodes.Wolf3D_Teeth.geometry}
        material={materials.Wolf3D_Teeth}
        // @ts-ignore
        skeleton={nodes.Wolf3D_Teeth.skeleton}
        // @ts-ignore
        morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
        // @ts-ignore
        morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
      />
    </group>
  );
};

export const SecondRoundInterviewFlow: React.FC<IProps> = ({
  videoConstraints,
  questions,
  fullScreenHandle,
  showTimer,
  isTestFinishing,
  handleFullScreenChange,
  onPressEnd,
  stopRecording,
}) => {
  const [queryParams] = useSearchParams();
  const assessmentId = queryParams.get("assessment");

  const {answer, setAnswer, isSubmitDisabled, setIsSubmitDisabled} = useChat();
  const {interview_details} = useAppCommonDataProvider();
  const {mutateAsync: saveResponse} = useUpdateSecondRoundResponse(
    assessmentId!
  );

  const cameraControls = useRef<CameraControls>(null);

  const [showIndividualQuestionTimer, setShowIndividualQuestionTimer] =
    useState<boolean>(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [saveRecording, setSaveRecording] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [images, setImages] = useState<string[]>([]);
  const [pingedScreenshots, setPingedScreenshots] = useState<boolean>(true);
  const [processingAudio, setProcessingAudio] = useState<boolean>(false);
  const [analyzingSentiments, setAnalyzingSentiments] =
    useState<boolean>(false);

  async function handleQuestionChange() {
    setAnalyzingSentiments(true);
    await emotionInstance
      .post("/analyze_emotion", {
        documentId: interview_details?.data.assessment.main_question_id!,
        questionId: questions[currentQuestionIndex].ai_question_id,
        s3Urls: images,
      })
      .finally(async () => {
        setImages([]);
        await saveResponse({
          answer,
          questionId: questions[currentQuestionIndex].id.toString(),
        });

        if (currentQuestionIndex + 1 === questions.length) {
          stopRecording();
          return;
        }

        setPingedScreenshots(true);
        setIsSubmitDisabled?.(true);
        setShowIndividualQuestionTimer(false);
        setAnswer?.("");
        const unitProgress = 100 / questions.length;
        setProgress(progress + unitProgress);
        setAnalyzingSentiments(false);

        setTimeout(() => {
          setCurrentQuestionIndex((prev) => prev + 1);
          setShowIndividualQuestionTimer(true);
        }, 10);
      });
  }

  async function onCaptureScreenshot(data: any) {
    const res = await uploadFileToS3(
      data,
      interview_details?.data.assessment.ai_assessment_id + ".jpg",
      "image/jpeg",
      "base64"
    );
    setImages((prevArray) => [...prevArray, res]);
  }

  useEffect(() => {
    setShowIndividualQuestionTimer(true);
    setTimeout(() => {
      const scrollingElement = document.scrollingElement || document.body;
      scrollingElement.scrollTop = scrollingElement.scrollHeight;
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      cameraControls.current?.setLookAt(0, 1.5, 1.5, 0, 1.5, 0, true);
    }, 3000);
  }, [cameraControls.current]);

  useEffect(() => {
    if (!showIndividualQuestionTimer) {
      setShowIndividualQuestionTimer(true);
      setSaveRecording(false);
    }
  }, [showIndividualQuestionTimer]);

  return (
    <FullScreen
      className="fullScreenStyle"
      handle={fullScreenHandle}
      onChange={handleFullScreenChange}
    >
      <main className="bg-white-900 px-6">
        <header className={`${interviewCommonStyles.behavior__header}`}>
          <div className="mt-8">
            <img
              src={
                "https://images.ctfassets.net/7oc31naqqojs/2anCoDyKXuDOb4MsNCpZXw/d6cf3e39be14d8ef234e9b33948e85f6/Sourcebae-logo.jpg"
              }
              alt="Logo"
            />
          </div>
          <h2 className="mt-10 text-[30px] font-bold">Candidate Screening</h2>
          <div className="flex justify-end mt-8 font-extrabold text-[24px]">
            {progress}%
          </div>
          <BorderLinearProgress
            variant="determinate"
            value={progress}
            className="w-full rounded-full mt-[12px]"
          />
        </header>

        <div className={"flex mt-16 h-60vh"}>
          <div className={styles.container}>
            <Row classNames={styles.wrapper}>
              <div className="w-full h-full flex">
                <Row classNames={styles.own__feed}>
                  <section
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    className="webcam__container relative"
                  >
                    {videoConstraints.width !== 0 && (
                      <Webcam
                        className="min-w-full h-full"
                        pingedScreenshots={pingedScreenshots}
                        onCaptureScreenshot={onCaptureScreenshot}
                        pingInterval={
                          (SecondRoundPerQuestionTime /
                            SecondRoundScreenshotsPerQuestion) *
                          1000
                        }
                        screenshotFormat="image/jpeg"
                      />
                    )}
                    {showTimer === false && (
                      <div className="absolute bottom-8 left-[2px] border border-[#FFFFFF]/50 rounded-xl bg-white/20 w-[222px]">
                        <Leva hidden />
                        <Canvas shadows camera={{position: [0, 0, 1], fov: 20}}>
                          <>
                            <Suspense fallback={<>loading...</>}>
                              <CameraControls ref={cameraControls} />
                              <Environment preset="sunset" />
                              <Model
                                currentQuestion={
                                  questions?.[currentQuestionIndex]
                                }
                                saveRecording={saveRecording}
                                setProcessingAudio={setProcessingAudio}
                              />
                              <ContactShadows opacity={0.7} />
                            </Suspense>
                          </>
                        </Canvas>
                      </div>
                    )}
                  </section>
                </Row>
                {showTimer === false && (
                  <Row classNames={styles.questions__card}>
                    <div
                      className=" rounded-[16px] pt-[36px] px-[24px] w-4/5 h-full"
                      style={{
                        boxShadow: "0px 0px 22.98px 0px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div className="flex justify-between items-center">
                        <div className="font-bold text-[28px]">
                          Question - {currentQuestionIndex + 1}
                        </div>
                        <div className="text-black/50">
                          {currentQuestionIndex + 1}/{questions.length}
                        </div>
                      </div>
                      <div className="mt-[28px] text-base font-medium">
                        {questions?.[currentQuestionIndex]?.question}
                      </div>
                      <div className="mt-[24px] text-[#1453FF] bg-[#F5F7FF] p-[20px] font-normal rounded-[16px] border border-dashed border-[#1453FF] max-h-[180px] overflow-scroll">
                        <span className="font-bold">Answer</span> - {answer}
                      </div>
                      <div className="flex justify-end pt-9">
                        <Button
                          onClick={handleQuestionChange}
                          variant="contained"
                          disabled={
                            isSubmitDisabled ||
                            processingAudio ||
                            analyzingSentiments
                          }
                        >
                          {showIndividualQuestionTimer &&
                            (processingAudio ? (
                              <p>Processing your response...</p>
                            ) : analyzingSentiments ? (
                              <p>Analyzing sentiments...</p>
                            ) : (
                              <>
                                Next
                                <span>
                                  {showIndividualQuestionTimer && (
                                    <CountDownTimer
                                      time={SecondRoundPerQuestionTime}
                                      sx={{
                                        color: AppColors.WHITE,
                                        fontSize: 14,
                                        marginLeft: 2,
                                        display: answer ? "none" : "block",
                                      }}
                                      onStopTimer={() => {
                                        setIsSubmitDisabled?.(false);
                                        setSaveRecording(true);
                                        setPingedScreenshots(false);
                                        setProcessingAudio(true);
                                      }}
                                    />
                                  )}
                                </span>
                              </>
                            ))}
                        </Button>
                      </div>
                    </div>
                  </Row>
                )}
              </div>
            </Row>
          </div>
        </div>
      </main>
    </FullScreen>
  );
};

useGLTF.preload("/models/64f1a714fe61576b46f27ca2.glb");
useGLTF.preload("/models/animations.glb");
