import React from "react";
import {Typography} from "@mui/material";
import Modal from "react-responsive-modal";

import {CustomModalBaseProps} from "globals/types/globalTypes";
import {halfModalContainer} from "globals/constants/styles";
import {AppColors} from "globals/appcolors";

interface IProps extends CustomModalBaseProps {}

export const AssessmentForcefullyFinished: React.FC<IProps> = ({
  open,
  onClose,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      showCloseIcon={false}
      styles={{modal: halfModalContainer}}
    >
      <Typography
        variant="h3"
        color={AppColors.REPORTCARD_SECONDARY}
        fontWeight={"bold"}
      >
        We're sorry!
      </Typography>
      <Typography
        sx={{marginTop: 2}}
        variant="body1"
        fontSize={17}
        fontWeight={"500"}
        color={AppColors.LIGHTBLACK_300}
      >
        Your assessment was finished because you were away from the assessment
        page for too long which is an indication of a possible attempt to cheat!
      </Typography>
    </Modal>
  );
};
