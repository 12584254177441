import React from "react";
// import {LandingPageHeader} from "../LandingPageHeader";
import styles from "./styles.module.scss";
import {Images} from "Images";
import {AppColors} from "globals/appcolors";
import {LongArrowRight} from "components/Icons";

export const Pricing: React.FC = () => {
  return (
    <>
      {/* <LandingPageHeader /> */}
      <div className={styles.pricing__wrap}>
        <div className={styles.pricing__first__div}>
          <h1> Recruitbae</h1>
          <p>Powered By</p>
          <img src={Images.WhiteLogo} alt="" />
        </div>
        <div className={styles.pricing__second__div}>
          <h1> Recruitbae</h1>
          <p>hire the best with no fee and no stress</p>
          <button>
            Take Product Tour
            <LongArrowRight color={AppColors.WHITE} size={25} />
          </button>
        </div>
      </div>
    </>
  );
};
