import React from "react";
import styles from "./CollegeLandingPageStyles.module.scss";
import strings from "globals/constants/strings.json";
import {Images} from "Images/index";

export const HeroSection: React.FC = () => {
  return (
    <div className="mb-4">
      <div className={styles.heroSection_wrap}>
        <div className={styles.heading_wrap}>
          <h1 className={styles.title}>
            {strings.College_landing.hero_section.title}
          </h1>
          <div className={styles.header_red_circle}></div>
        </div>
        <div className={styles.subheading_wrap}>
          <p className={styles.subtitle}>
            {strings.College_landing.hero_section.sub_title}
          </p>
        </div>
        <div className={styles.button_wrap}>
          <button className={styles.college_page_button}>
            {strings.College_landing.hero_section.button_text}
          </button>
          <div className={styles.header_blue_circle}></div>
        </div>
      </div>
      <div className={styles.header_banner_wrap}>
        <img src={Images.heroBanner} alt="" />
      </div>
    </div>
  );
};
