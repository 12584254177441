import React, {useEffect, useState} from "react";
import styles from "./SignUpStyles.module.scss";
import {Images} from "Images";
import strings from "globals/constants/strings.json";
import {PersonalDetailForm} from "./PersonalDetailForm";
import {CompanyDetailForm} from "./CompanyDetailForm";
import {CreatePasswordForm} from "./CreatePasswordForm";
import {
  SignupPersonalDetailFormValues,
  SignupCompanyDetailFormValues,
} from "globals/types/globalTypes";
import {useNavigate} from "react-router-dom";
import {RouteLinks} from "globals/constants/routeLinks";

export const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const [formVisibility, setFormVisibility] = useState({
    secondForm: false,
    thirdForm: false,
  });
  const handleFirstFormClick = (data: SignupPersonalDetailFormValues) => {
    setFormVisibility({...formVisibility, secondForm: true});
  };
  const handleSecondFormClick = (data: SignupCompanyDetailFormValues) => {
    setFormVisibility({...formVisibility, thirdForm: true});
  };
  useEffect(() => {
    console.log({formVisibility});
  }, [formVisibility]);
  return (
    <div className={styles.signup__wrapper}>
      <div className={styles.signup__header}>
        <div className={styles.signup__header__logo}>
          <img src={Images.logo} alt="" onClick={() => navigate("/")} />
        </div>
        <div className={styles.signup__header__title}>
          <p>Already Have An Account? {""}</p>

          <span
            onClick={() => navigate(`/${RouteLinks.Login}`)}
            className="cursor-pointer"
          >
            {" "}
            Log In
          </span>
        </div>
      </div>

      {/* // First Form */}

      <div className={styles.signup__form_wrapper}>
        <div className={styles.signup__form__title}>
          {strings.SignUp.formTitle}
        </div>
        <div className={styles.signup__form__tab_row}>
          <p>{strings.SignUp.tab_1}</p>
          <div className={styles.signup__form__tab_row_dotted_line}></div>
          <p className="text-monoGrey-200">{strings.SignUp.tab_2}</p>
          <div className={styles.signup__form__tab_row_dotted_line}></div>
          <p className="text-monoGrey-200">{strings.SignUp.tab_3}</p>
        </div>
        <div className={styles.signup__form__details_wrap}>
          <div className={styles.signup__form__details_title}>
            <p className="text-47 text-lightBlack-800 font-bold">01</p>
            <p className="text-black-150 text-20 font-bold">
              {strings.SignUp.tab_1}
            </p>
          </div>
          <div className={styles.signup__main__form}>
            <PersonalDetailForm handleFirstFormClick={handleFirstFormClick} />
          </div>
        </div>
      </div>

      {/* // Second form  */}
      <div
        className={styles.signup__form_wrapper}
        style={
          formVisibility.secondForm
            ? {display: "block", backgroundColor: "white"}
            : {display: "none"}
        }
      >
        <div className={styles.signup__form__title}>
          {strings.SignUp.formTitle}
        </div>
        <div className={styles.signup__form__tab_row}>
          <p className="text-primary">{strings.SignUp.tab_1}</p>
          <div className="border-t-2 border-dashed border-primary-100 w-1/5 mx-2   "></div>
          <p>{strings.SignUp.tab_2}</p>
          <div className="border-t-2 border-dashed border-primary-100 w-1/5 mx-2   "></div>
          <p className="text-monoGrey-200">{strings.SignUp.tab_3}</p>
        </div>
        <div className={styles.signup__form__details_wrap}>
          <div className={styles.signup__form__details_title}>
            <p className="text-47 text-lightBlack-800 font-bold">02</p>
            <p className="text-black-150 text-20 font-bold">
              {strings.SignUp.tab_2}
            </p>
          </div>
          <div className={styles.signup__main__form} id="company-form">
            <CompanyDetailForm handleSecondFormClick={handleSecondFormClick} />
          </div>
        </div>
      </div>

      {/* // Third Form  */}
      <div
        className={styles.signup__form_wrapper}
        style={
          formVisibility.thirdForm ? {display: "block"} : {display: "none"}
        }
      >
        <div className={styles.signup__form__title}>
          {strings.SignUp.formTitle}
        </div>
        <div className={styles.signup__form__tab_row}>
          <p className="text-primary">{strings.SignUp.tab_1}</p>
          <div className="border-t-2 border-dashed border-primary-100 w-1/5 mx-2   "></div>
          <p className="text-primary">{strings.SignUp.tab_2}</p>
          <div className="border-t-2 border-dashed border-primary-100 w-1/5 mx-2   "></div>
          <p>{strings.SignUp.tab_3}</p>
        </div>
        <div className={styles.signup__form__details_wrap}>
          <div
            className={styles.signup__form__details_title}
            style={{marginTop: "0px"}}
          >
            <p className="text-47 text-lightBlack-800 font-bold">03</p>
            <p className="text-black-150 text-20 font-bold">
              {strings.SignUp.tab_3}
            </p>
          </div>
          <div className={styles.signup__main__form}>
            <CreatePasswordForm />
          </div>
        </div>
      </div>
    </div>
  );
};
