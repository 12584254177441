import React, {CSSProperties} from "react";
import AppBody from "components/Containers/AppBody/AppBody";
import {useLocation} from "react-router-dom";
import {specialRoutes} from "components/NavBars/AppNav/routes";
import styles from "./MainContainer.module.scss";
import {RouteLinks} from "globals/constants/routeLinks";
import cookie from "react-cookies";
import {COOKIESKEYS} from "globals/constants/cookiesKeys";

const loginStyle: CSSProperties = {
  backgroundSize: "cover",
  backgroundColor: "#ffffff",
};

const backgroundRoutes = [
  "/",
  "/interview",
  `/${RouteLinks.Interview__Questions}`,
];

interface Props {
  children: React.ReactChild;
}

const MainContainer: React.FC<Props> = ({children}) => {
  // const userDetails = cookie.load(COOKIESKEYS.USER_DETAILS);
  const userDetails = JSON.parse(localStorage.getItem("USER_DETAILS")!);

  const location = useLocation();
  let lochash = `/${location.pathname.replace("/", " ").trim().split("/")[0]}`;
  const isLoginPage =
    backgroundRoutes.includes(location.pathname) ||
    specialRoutes.includes(lochash);

  return (
    <div
      className={styles.main_container}
      style={isLoginPage ? loginStyle : {}}
    >
      <div style={{width: isLoginPage ? "100%" : "97%", height: "100%"}}>
        {/* {!(specialRoutes.includes(location.pathname) || specialRoutes.includes(lochash) ) && <Header user={`${userDetails?.user?.first_name} ${userDetails?.user?.last_name}`} />} */}
        <AppBody>{children}</AppBody>
      </div>
    </div>
  );
};

export default MainContainer;
