import React from "react";
import styles from "./SignUpStyles.module.scss";
import {HashLink} from "react-router-hash-link";
import {Formik} from "formik";
import * as yup from "yup";
import {SignupPersonalDetailFormValues} from "globals/types/globalTypes";

interface Props {
  handleFirstFormClick: (data: SignupPersonalDetailFormValues) => void;
}

let initialValues: SignupPersonalDetailFormValues = {
  first_name: "",
  last_name: "",
  user_name: "",
  email: "",
  phone_no: "",
};

const schema = yup.lazy(() =>
  yup.object().shape({
    first_name: yup
      .string()
      .min(2, "Mininum 2 characters")
      .max(30, "Maximum 30 characters")
      .required("first name is required"),
    last_name: yup
      .string()
      .min(2, "Mininum 2 characters")
      .max(30, "Maximum 30 characters"),
    user_name: yup
      .string()
      .min(1, "Mininum 1 characters")
      .max(15, "Maximum 15 characters")
      .required("You must enter a username"),
    email: yup
      .string()
      .required("Email is required")
      .email("Enter a valid email"),
    phone_no: yup
      .string()
      .required("Phone Number is required")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .min(10, "to short")
      .max(10, "to long"),
  })
);

export const PersonalDetailForm: React.FC<Props> = ({handleFirstFormClick}) => {
  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(data) => {
          handleFirstFormClick(data);
          // console.log("clickedds");
        }}
        validationSchema={schema}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          setFieldValue,
          errors,
          status,
          touched,
        }) => {
          return (
            <form className={styles.signup__forms}>
              <div className={styles.form__field__wrap}>
                <label htmlFor="">
                  First Name <span className="text-red-300">*</span>
                </label>
                <input
                  type="text"
                  name="first_name"
                  placeholder="Type Here"
                  onChange={handleChange}
                />
                {errors.first_name && touched.first_name && (
                  <p className={styles.error__text}>{errors?.first_name}</p>
                )}
              </div>
              <div className={styles.form__field__wrap}>
                <label htmlFor="">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  placeholder="Type Here"
                  onChange={handleChange}
                />
                {errors.last_name && touched.last_name && (
                  <p className={styles.error__text}>{errors?.last_name}</p>
                )}
              </div>
              <div className={styles.form__field__wrap}>
                <label htmlFor="">
                  User Name <span className="text-red-300">*</span>
                </label>
                <input
                  type="text"
                  name="user_name"
                  placeholder="Type Here"
                  onChange={handleChange}
                />
                {errors.user_name && touched.user_name && (
                  <p className={styles.error__text}>{errors?.user_name}</p>
                )}
              </div>
              <div className={styles.form__field__wrap}>
                <label htmlFor="">
                  Email <span className="text-red-300">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Type Here"
                  onChange={handleChange}
                />
                {errors.email && touched.email && (
                  <p className={styles.error__text}>{errors?.email}</p>
                )}
              </div>
              <div className={styles.form__field__wrap}>
                <label htmlFor="">
                  Phone Number <span className="text-red-300">*</span>
                </label>
                <input
                  type="number"
                  name="phone_no"
                  placeholder="Type Here"
                  onChange={handleChange}
                />
                {errors.phone_no && touched.phone_no && (
                  <p className={styles.error__text}>{errors?.phone_no}</p>
                )}
              </div>
              <div className={`${styles.form__field__wrap} md:justify-center`}>
                <button
                  className={styles.signup__form__button_wrap}
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <HashLink
                    smooth
                    to="/sign-up#company-detail"
                    scroll={(el) => el.scrollIntoView({behavior: "smooth"})}
                  >
                    Next
                  </HashLink>
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
